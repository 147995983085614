import React from "react";
import {connect} from 'react-redux';
import {
    fetchBooksByOriginalAuthor
} from '../reducers/content/actions';

import BookPreviewCard from '../componentsBange/bookPreviewCard/BookPreviewCard';
import {isMobile} from "../utils/device";

class ByAuthor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            books: []
        }
    }

    componentDidMount() {
        const { params } = this.props.match;

        this.props.fetchBooksByOriginalAuthor(params.author,(books) => {
            this.setState({loading: false, books: books});
        }, () => {
            this.setState({loading: false, books: []});
        });
    }

    render() {

        const {books} = this.state;

        return (

            <div className={"main-content-container container" + (isMobile ? " d-flex" : "")}>
                <div className="row" style={{marginTop: isMobile ? "148px" : "120px"}}>
                    <div className="col-12">
                        {books.length > 0 && (
                            <div>
                                <div className="row">
                                    {books && books.map((element, index) => {
                                        return (
                                            <div key={index} className="col-12 col-lg-4 p-3 my-3">
                                                <BookPreviewCard animate={false} title={element.title}
                                                                 isbn={element.isbn} link={element.link}
                                                                 author={element.author}
                                                                 oAuthor={element.originalAuthor}
                                                                 mediaTypes={element.mediaTypes}
                                                                 coverURL={element.coverURL}/>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        )

    }
}

ByAuthor.propTypes = {
    /**
     * The small stats data.
     */

};

ByAuthor.defaultProps = {};

const mapStateToProps = state => ({
    books: state.content.books,
});

const mapDispatchToProps = dispatch => ({
    fetchBooksByOriginalAuthor: (author, successCb, errorCb) => dispatch(fetchBooksByOriginalAuthor(author, successCb, errorCb))
});


export default connect(mapStateToProps, mapDispatchToProps)(ByAuthor);

