const apiAddress = (() => {

    let dynURL;
    if (typeof document != "undefined") {
        if ((dynURL = document.URL)) {
            if (dynURL.search(/dev\.lektueren-verstehen\.de/) >= 0)
                return "https://devapi.lektueren-verstehen.de";


            if (dynURL.search(/lektueren-verstehen\.de/) >= 0)
                return "https://api.lektueren-verstehen.de";
        }
    }
    return "http://localhost:3000";
})();

export default apiAddress;
