import React from 'react';
import Button from "../button/Button";
import './modalcontentgeoinfo.css';
class ModalContentGeoInfo extends React.Component {


    constructor(props) {
        super(props);
        this.state = {}
    }

    onReloadClick() {
        if (this.props.hasOwnProperty('onRecheckClick')) {
            this.props.onRecheckClick();
        } else {
            window.location.reload();
        }
    }

    onRecheckClick() {
        if (this.props.hasOwnProperty('onRecheckClick')) {
            this.props.onRecheckClick();
        } else {
            window.localStorage.removeItem("usr_country_code");
            window.localStorage.removeItem("usr_is_eu")
            window.localStorage.removeItem("usr_ip");
            window.localStorage.removeItem("usr_country_name");
            window.localStorage.removeItem("usr_city");
            window.localStorage.removeItem("usr_current_time");
            // window.localStorage.removeItem("usr_info_displayed");
            window.location.reload();
        }
    }

    render() {
        return (
            <div className="geoinfo modalContainer">
                Herzlich Willkommen auf dem Lektürehilfen-Portal von Königs Erläuterungen!<br/>
                Im Moment können leider nur Kunden aus EU-Mitgliedstaaten einen Einzelkauf
                tätigen oder ein Abonnement abschließen.<br/>
                Die Schnellübersichten der einzelnen Titel und die Infothek stehen dennoch wie
                gewohnt zur Verfügung.<br/>
                Es können auch E-Book-Codes eingelöst werden<br/>
                Viel Spaß beim Stöbern!<br/><br/>
                Die Königs-Erläuterungen-Redaktion
                <div className={'btns'} style={{width: "100%", padding: "2rem 0"}}>
                    <Button colorScheme="inverted btnok"
                            size="medium"
                            onClick={this.onReloadClick.bind(this)}>
                        OK
                    </Button>
                    <Button colorScheme="inverted btncheck"
                            size="medium"
                            onClick={this.onRecheckClick.bind(this)}>
                        Länderprüfung erneut starten
                    </Button>
                </div>
            </div>
        )

    }

}

export default ModalContentGeoInfo;
