import {
    GET_PRODUCTS, GET_PRODUCT_SINGLE_BOOK,GET_PRODUCT_FLATRATE
  } from "./actions";
  
  
  
  
  const initialState = {
    products: []
  };
  
  export default function products(state = initialState, action={}) {
    switch(action.type) {
      case GET_PRODUCTS: {
        return {
          ...state,
          products: action.products
        };
      }

      case GET_PRODUCT_SINGLE_BOOK: {
        return {
          ...state,
          singleBook: action.singleBook
        };
      }
      case GET_PRODUCT_FLATRATE: {
        return {
          ...state,
          flatrate: action.flatrate
        };
      }
      default: {
        return state;
      }
    }
  }
  