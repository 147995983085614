import React from "react";
import PropTypes from "prop-types";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainFooter from "../components/layout/MainFooter";
import {LAYOUT_TYPES} from "../utils/constants";
import './default.css';
import Teaser from '../componentsBange/Teaser/Teaser'
const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  if (children.props.location.pathname === "/") {
    /** to remove ?subId=xxx onError and add subId to Teaser props*/
    const urlParams = new URLSearchParams(window.location.search);
    const subId = urlParams.get('subId');
    //indow.history.pushState({}, document.title, window.location.pathname)}
    return (
      <div  style={{display: "flex", flexDirection: "column"}}>
        <Teaser subId={subId}
                onError={() =>window.history.pushState({}, document.title, window.location.pathname)}
        />
            {children}
            {noFooter && <MainFooter />}

      </div>
    );
  };
  if (children.props.location.pathname === "/register" || children.props.location.pathname === "/register/") {
    return (
      <div className="landing-background" style={{display: "flex", flexDirection: "column"}}>
            {!noNavbar &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={false} transparent={true}/>}
            {children}
            {noFooter && <MainFooter transparent={true}/>}

      </div>
    );
  }
  if (children.props.location.pathname === "/my/account") {

    return (
      <div className="landing-background" style={{display: "flex", flexDirection: "column"}}>
            {!noNavbar &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={false} transparent={true} transparentMobile={false}/>}
            {children}
            {noFooter && <MainFooter transparent={true} transparentMobile={false}/>}

      </div>
    );
  }

  if (children.props.location.pathname === "/my/abos" || children.props.location.pathname === "/my/abos/") {

    return (
      <div className="landing-background" style={{display: "flex", flexDirection: "column"}}>
            {!noNavbar &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={false} transparent={true} transparentMobile={false}/>}
            {children}
            {noFooter && <MainFooter transparent={true} transparentMobile={false}/>}

      </div>
    );
  }

  if (children.props.location.pathname === "/my/titles" || children.props.location.pathname === "/my/titles/") {

    return (
      <div className="landing-background" style={{display: "flex", flexDirection: "column"}}>
            {!noNavbar &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={false} transparent={false}/>}
            {children}
            {noFooter && <MainFooter transparent={false}/>}

      </div>
    );
  }

  if (children.props.location.pathname === "/impressum") {

    return (
      <div className="landing-background" style={{display: "flex", flexDirection: "column"}}>
            {!noNavbar &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={false} transparent={false}/>}
            {children}
            {noFooter && <MainFooter transparent={true} transparentMobile={false}/>}

      </div>
    );
  }


  if (children.props.location.pathname.includes("/confirm")) {

    return (
      <div className="landing-background" style={{display: "flex", flexDirection: "column"}}>
            {!noNavbar &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={false} transparent={true}/>}
            {children}
            {noFooter && <MainFooter transparent={true}/>}

      </div>
    );
  }

  if (children.props.location.pathname === "/forgot-password") {

    return (
      <div style={{display: "flex", flexDirection: "column",height:"100%"}}>
      {!noNavbar &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={true} transparent={false}/>}
      {children}
      {noFooter && <MainFooter transparent={false}/>}

</div>
    );
  }

  return (
    <div style={{display: "flex", flexDirection: "column",height:"100%"}}>
          {!noNavbar &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={true} stickyTop={true} transparent={false}/>}
          {children}
          {noFooter && <MainFooter transparent={false}/>}

    </div>
  );
}


DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: true
};

export default DefaultLayout;
