import React ,{Fragment} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
    // We can inject some CSS into the DOM.
    const styles = {
        root: {
            '& label': {
                color: '#BBBBBB',
                ' font-family': 'Montserrat !important',
                'font-style': 'normal',
                'font-weight': 400,
                'font-size': '1.125rem',
                'line-height': '1.375rem'
              },
            '& label.Mui-focused': {
                color: '#BBBBBB',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#008000',
                'border-bottom-width':'2px'
              },
              '& .MuiInput-underline:before': {
                borderBottomColor: '#008000',
                'border-bottom-width':'1px'
              },
              '& .MuiInput-underline:hover:before': {
                borderBottomColor: '#008000',
                'border-bottom-width':'2px'
              },
              '& .MuiInput-input': {
                  'font-family': 'Montserrat',
                  'font-style': 'normal',
                  'font-weight': 500,
                   'font-size': '1.125rem',
                   'line-height':' 1.375rem',
                    'color': '#383838',
              },
        },
      };

 function Input({name, classes,className,valid,defaultValue,autoFocus,onChangeHandler,showpw,input: {type,  label,required, maxlength,placeholder, id,disabled}, changeHandler}) {
   
  const inputProps = {
        // can not be undefined
        id: id,
        type: type,
        //value: value,
        name:name,
        required:required,
        inputProps:{maxLength:maxlength},
        placeholder: placeholder,
        disabled: disabled,
        fullWidth:true,
        endAdornment:(showpw?showpw:null)
         
        
      };




      if (valid === false) {
        return (
          <Fragment>
        <TextField
        error
        label={label}
        className={clsx(classes.root, className)}
        margin="normal"
        name={name}
        InputProps={inputProps}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
       onChange = {(e) =>  {if(onChangeHandler){onChangeHandler(e)}}}
        onBlur = {(e) => {changeHandler(e)}}
        fullWidth
      />
        </Fragment>
        )
      }

    return(
      <Fragment>
        <TextField
        label={label}
        className={clsx(classes.root, className)}
        margin="normal"
        name={name}
        InputProps={inputProps}
        defaultValue={defaultValue}
        onChange = {(e) => {if(onChangeHandler){onChangeHandler(e)}}}
       onBlur = {(e) => {changeHandler(e)}}
        fullWidth
      />
        </Fragment>

       );
}

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  changeHandler: PropTypes.func
};

Input.defaultProps = {
  changeHandler: (e) => {
    console.log("no custom onChange-handler has been implemented, using default handler..");
  },
  value: ""
}

export default withStyles(styles)(Input);
