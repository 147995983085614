import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.css';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

    // We can inject some CSS into the DOM.
    const styles = {
      'PrivateSwitchBase-root-90' :{
        padding: '12px',
        'padding-left': '0px'
      },
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
          
        },
        icon: {
          borderRadius: 6,
          'border-width':'2px',
          width: 27,
          height: 27,
          boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
          'input:hover ~ &': {
            backgroundColor: '#005CA8',
            '&:before': {
              display: 'block',
              width: 27,
              height: 27,
              backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
              content: '""',
            },
          },
          'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
          },
        },
        checkedIcon: {
          backgroundColor: '#005CA8',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
          '&:before': {
            display: 'block',
            width: 27,
            height: 27,
            backgroundImage:
              "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
              " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
              "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
          },
          'input:hover ~ &': {
            backgroundColor: '#BABABA',

          },
        },
      };

 function CheckboxWithLabel({inputName, classes,defaultValue,className,checkbox: {label,required,disabled},changeHandler}) {

    const [state, setState] = React.useState({
        checked: defaultValue,
      });
      
    const inputProps = {
        'aria-label': 'primary checkbox',
        required: required,
      };

      const handleChange =  event => {
        setState({ ...state, checked: event.target.checked });

        if(changeHandler) changeHandler(event)
      };

    return(
        <div className="checkbox-wrapper">
          <Checkbox
        checked={state.checked}
        onChange={(e)=> handleChange(e)}
        color="primary"
        name={inputName}
        disabled={disabled}
        inputProps={inputProps}
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      />
            <div className="d-flex align-items-center">
            <label className="checkbox-label">{label}</label>
            </div>

        </div>

       );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired
};

export default withStyles(styles)(CheckboxWithLabel);
