import React from 'react';
import PropTypes from 'prop-types';
import {isMobile} from "../../utils/device";

function HeadingSeo({type, children, headingPriority}) {
    if (headingPriority === 1){
        if (isMobile) {
            return (
                <h1 className={'headingStyle ' + type+"mobile"}>{children}</h1>
            )
        }
        return (
            <h1 className={'headingStyle ' + type}>{children}</h1>
        )
    }

    if (headingPriority === 2){
        if (isMobile) {
            return (
                <h2 className={'headingStyle ' + type+"mobile"}>{children}</h2>
            )
        }
        return (
            <h2 className={'headingStyle ' + type}>{children}</h2>
        )
    }

    if (headingPriority === 3){
        if (isMobile) {
            return (
                <h3 className={'headingStyle ' + type+"mobile"}>{children}</h3>
            )
        }
        return (
            <h3 className={'headingStyle ' + type}>{children}</h3>
        )
    }
    
}

HeadingSeo.proptTypes = {
    type: PropTypes.oneOf(["primary", "secondary", "tertiary"]).isRequired,
    children: PropTypes.node.isRequired
}



export default HeadingSeo;
