import {
    CREATE_PAYMENT,
    GET_PAYMENT,
    EXECUTE_PAYMENT,
    GET_SALE,
    CREATE_SUBSCRIPTION,
    GET_SUBSCRIPTION
  } from "./actions";
  
  
  
  
  const initialState = {
    paymentResource: null,
    paymentExecution: null,
    sale: null
  };
  
  export default function payment(state = initialState, action={}) {
    switch(action.type) {
      case CREATE_PAYMENT: {
        const { paymentRes } = action;
        return {
          ...state,
          paymentResource: paymentRes
        };
      }
      case GET_PAYMENT: {
        const { paymentRes } = action;
        return {
          ...state,
          paymentResource: paymentRes
        };
      }
      case EXECUTE_PAYMENT: {
        const { payExe } = action;
        return {
          ...state,
          paymentExecution: payExe
        };
      }
      case GET_SALE: {
        const { sale } = action;
        return {
          ...state,
          sale: sale
        };
      }
      case CREATE_SUBSCRIPTION: {
        const { subRes } = action;
        return {
          ...state,
          subRes: subRes
        };
      }
      case GET_SUBSCRIPTION: {
        const { subRes } = action;
        return {
          ...state,
          subRes: subRes
        };
      }
      default: {
        return state;
      }
    }
  }
  