import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import * as ContentService from "../reducers/content/actions";
import SearchBar from '../componentsBange/searchBar/SearchBar';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

class SearchBarContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
          searchTerm: props.searchTerm,
        }
        this.searchAPIDebounced = AwesomeDebouncePromise(this.search, 300);
    }

    search = (text) => {
      // console.log("debounced text", text)
      this.props.search(text, () => {

      }, () => {

      })
    }

  onChange = async (value) => {
    this.setState({searchTerm: value})
     await this.searchAPIDebounced(value);
  }

  debounce = (func, wait, immediate) => {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

    onReset = () => {
      this.onChange("");
    }



    render() {

        const {styles,  resetable, height, className,colorScheme,placeholder} = this.props;
        return (
            <Fragment>
              <SearchBar className={className} height={(height ? height : 50)} resetable={resetable} onEnter={this.props.onSubmit} searchTerm={this.state.searchTerm} styles={styles} onReset={this.onReset} onSubmit={this.props.onSubmit} onChange={(value) => {this.debounce(this.onChange(value))}} placeHolder={placeholder?placeholder:"Suchen"} size="dynamic" colorScheme={colorScheme} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
  searchTerm: state.content.searchTerm,
});

const mapDispatchToProps = dispatch => ({
  search: (searchTerm, successCb, errorCb) => dispatch(ContentService.search(searchTerm, successCb, errorCb)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchBarContainer);
