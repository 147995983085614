import * as axios from 'axios';
import apiAddress from './apiAddress';

axios.defaults.withCredentials = true

const apiClient = axios.create({
    baseURL: apiAddress,
    timeout: 10000,
    headers: {"Content-Type": "application/json"},
});

export default class Https {
    static get(path) {
        // apiClient.responseType = 'blob'
        return apiClient({
            method: 'get',
            url: path,
        });
    }

    static sync(path) {
        const syncApiClient = axios.create({
            baseURL: apiAddress,
            timeout: 0,
            headers: {"Content-Type": "application/json"}
        });
        return syncApiClient({
            method: 'get',
            url: path,
        })
    }

    static getBlob(path) {
        const client = axios.create({
            baseURL: apiAddress,
            timeout: 10000,
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/csv"
            },
            responseType: 'blob'
        });
        return client({
            method: 'get',
            url: path,
            headers: {'Authorization': apiClient.defaults.headers.common['Authorization']},
            responseType: 'blob', // important
        })
    }

    static export(path, name, successCB, errorCB) {
        axios({
            url: apiAddress + path, //your url
            method: 'GET',
            headers: {'Authorization': apiClient.defaults.headers.common['Authorization']},
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name + '.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
            if (successCB) successCB();
        });
    }

    static post(path, body, token) {
        if (token) {
            Https.setAccessToken(token.accessToken)
        }
        return apiClient({
            method: 'post',
            url: path,
            data: JSON.stringify(body),
        })
    }

    static put(path, body, token) {
        if (token) {
            Https.setAccessToken(token.accessToken)
        }
        return apiClient({
            method: 'put',
            url: path,
            data: JSON.stringify(body),
        })
    }

    static delete(path, body) {
        return apiClient({
            method: 'delete',
            url: path,
            data: JSON.stringify(body),
        });
    }

    static setAccessToken(token) {
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    static removeAccesToken() {
        apiClient.defaults.headers.common['Authorization'] = '';
    }

}
