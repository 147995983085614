import React from "react";

//STYLING
import './MyAbos.css'

//GRAPHICAL COMPONENTS
import Heading from '../componentsBange/heading/Heading'
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {
    orderSubscription, getSubscription
} from '../reducers/payment/actions';
import Lottie from 'lottie-react-web'
import animation from '../assets/images/animation.json';
import loader from '../assets/images/loader.json';
import Button from "../componentsBange/button/Button";
class CheckoutSubscription extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            orderSuccess: false,
            loading: true
        }
    }

    componentDidMount() {
        let subId = this.props.subId;
        console.log(subId);
        // subscription resource has already been created at this point, now check if sub status is active and then order
        this.props.orderSubscription(subId, this.orderSubSuccess, this.orderSubFailure);
    }



    orderSubSuccess = () => {
        this.props.getSubscription(this.props.subId, () => {
            this.setState({ orderSuccess: true, loading: false });
        }, () => {
            // could not get subscription, retry
        });

    }

    orderSubFailure = (errorCode) => {
         this.setState({ orderSuccess: false, loading: false });
         this.props.onError();
        /*switch (errorCode) {
            // timeout
            // --> retry
            default: 
            console.log("retrying");
            let subId = this.props.subId;
            console.log(subId);
            // subscription resource has already been created at this point, now check if sub status is active and then order
            this.props.orderSubscription(subId, this.orderSubSuccess, this.orderSubFailure);
            this.setState({loading: true });
            break;
        } */

        
    }

    render() {
        return (
            <div className="modalContainer">
                <div>
                    <Heading class="modal-title" type="primary">Abo abgeschlossen</Heading>
                </div>
                {/* customer approved via paypal and gets redirected to our page  */}
                {this.state.orderSuccess && !this.state.loading && (<Lottie style={{ width: "20%" }}
                    options={{
                        animationData: animation,
                        loop: false
                    }}
                />)}
                {/* subscription information has successfully been fetched */}
                {this.state.orderSuccess &&
                    <div className="mb-2 pt-2 text-center" style={{ color: "green", fontSize: "1.15em" }}>
                        Das Abonnement wurde erfolgreich abgeschlossen.
                </div>}
                {this.props.subscription && (
                    <div>
                        <Heading type="secondary" style={{ fontSize: "1.3em" }}>Detailübersicht</Heading>
                        <div style={{ fontSize: "1.15em" ,wordWrap: "break-word"}}>
                            <div className="row">
                                <div className="col-6">
                                    <label style={{ fontWeight: "bold" }}>Vor- und Nachname</label>
                                </div>
                                <div className="col-6">
                                    {this.props.subscription && this.props.subscription.subscriber.name.given_name + " " + this.props.subscription.subscriber.name.surname}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label style={{ fontWeight: "bold" }}>E-Mail</label>
                                </div>
                                <div className="col-6">
                                    {this.props.subscription && this.props.subscription.subscriber.email_address}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label style={{ fontWeight: "bold" }}>Startdatum</label>
                                </div>
                                <div className="col-6">
                                    {this.props.subscription && new Date(this.props.subscription.start_time).toLocaleDateString()}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label style={{ fontWeight: "bold" }}>Nächste Abbuchung</label>
                                </div>
                                <div className="col-6">
                                    {this.props.subscription && new Date(this.props.subscription.billing_info.next_billing_time).toLocaleDateString()}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    Sie können das Abonnement jederzeit monatlich kündigen.
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <a href={'/alle-arbeitshilfen'}
                                       style={{width: "50%", display: "inline-block", margin: "45px 25% 0 25%"}}>
                                        <Button colorScheme="inverted" size="medium">
                                            Weiter zu den Lektüren
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>)}
                {this.state.loading && (<Lottie style={{ width: "20%" }}
                    options={{
                        animationData: loader,
                    }}
                />)}
                 {/* customer already has subscription */}
                {!this.state.orderSuccess && this.props.subscription !== undefined && this.props.subscription.status === "ACTIVE" && <div>Das Abonnement wurde bereits freigeschalten oder es ist ein Fehler aufgetreten</div>}
                 {/* paypal subscription could not be activated  */}
                {!this.state.orderSuccess && !this.state.loading && 
                 <div>Das Abonnement konnte nicht freigeschalten werden. Es ist ein Fehler aufgetreten</div>}
            </div>
        )
    }
}
CheckoutSubscription.propTypes = {
    /**
     * The small stats data.
     */

};

CheckoutSubscription.defaultProps = {

};

const mapStateToProps = state => ({
    subscription: state.payment.subRes,
});

const mapDispatchToProps = dispatch => ({
    //getUserActiveSubscription: (successCB, errorCB) => dispatch(UserAction.getUserActiveSubscription(successCB, errorCB)),
    orderSubscription: (subid, successCb, errorCb) => dispatch(orderSubscription(subid, successCb, errorCb)),
    getSubscription: (subid, successCb, errorCb) => dispatch(getSubscription(subid, successCb, errorCb))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutSubscription));


