import Https from "../../services/api/apiClient";

export const FETCH_BOOKSHAVECODES = 'FETCH_BOOKSHAVECODES';
export const FETCH_BOOKSCODEUSERS = 'FETCH_BOOKSCODEUSERS';

export function fetchAllBooksCouponCount(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/codes/bookshavecodes')
            .then(res => {
                successCB(res.data.data);
                dispatch({
                    type: FETCH_BOOKSHAVECODES,
                    bookshavecodes: res.data.data
                });
            })
            .catch(err => {
                if (errorCB) errorCB(err);
                console.log('Couldnt not fetch bookshavecodes - ', err)
            })
    };
}

export function fetchAllBooksCodesUsers(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/codes/booksusers')
            .then(res => {
                successCB(res.data.data);
                dispatch({
                    type: FETCH_BOOKSCODEUSERS,
                    bookscodesusers: res.data.data
                });
            })
            .catch(err => {
                if (errorCB) errorCB(err);
                console.log('Couldnt not fetch /codes/booksusers - ', err)
            })
    };
}