import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


    // We can inject some CSS into the DOM.
    const styles = {
        select: {
            ' font-family': 'Montserrat !important',
            'font-style': 'normal',
            'font-weight': 600,
            'font-size': '18px',
            'line-height': '22px',
            color: '#383838',
          '&:before': {
              borderColor: '#008000',
              'border-bottom-width':'1px'
          },
          '&:after': {
              borderColor: '#008000',
              'border-bottom-width':'2px'
          },
          '&:hover:before': {
            borderColor: '#008000',
            'border-bottom-width':'2px'
        },
        '&:hover:after': {
            borderColor: '#008000',
            'border-bottom-width':'2px'
        }
      },
      inputLabel: {
          color: '#BBBBBB',
          ' font-family': 'Montserrat !important',
          'font-style': 'normal',
            'font-weight': 500,
            'font-size': '18px',
            'line-height': '22px'

      },
      formControl: {
        minWidth: 120,
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#BBBBBB'
          },
        '& .MuiInputLabel-shrink': {
            ' font-family': 'Montserrat !important',
            'font-style': 'normal',
            'font-weight': 600,
            'font-size': '12px',
            'line-height': '15px'
        },
        '& .MuiInput-underline:hover:after':{
            borderColor: '#008000',
            'border-bottom-width':'2px'
        }
      },

      };

 function Dropdown({classes,className,dropdown: {id,label,name,options},onChange}) {
    const [state, setState] = React.useState({
        value: '',
      });

      let menuItems = [];
      for (let i=0;i<options.length;i++){
          let item =  (<MenuItem value={options[i].value} key={i}>{options[i].label}</MenuItem>);
          menuItems.push(item);
      };
      const handleChange = event => {
        setState({
          value: event.target.value,
        });
      };

    return(
        <FormControl margin="normal" className={classes.formControl}>
        <InputLabel className={classes.inputLabel} htmlFor={name}>{label}</InputLabel>
        <Select
        className={classes.select}
          value={state.value}
          onChange={(e)=>{handleChange(e);onChange(e)}}
          inputProps={{
            name: name,
            id: id,
          }}
        >
            {menuItems}
        </Select>
      </FormControl>

       );
}
/*
Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired
}; */

export default withStyles(styles)(Dropdown);
