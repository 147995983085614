import React, {Component} from "react";
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody'
import {in_array} from '../modules/Functions';
import ModalContentGeoInfo from "./ModalContentGeoInfo/ModalContentGeoInfo";

class GeoInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openModal: false,
            openGeoInfoModal: false,
            openState: null,
            modalState: "",
            modalAction: null,
            email: "",
            password: "",
            loginError: false,
            loginLoading: false,
            setReload: false
        };
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            let euCountries = [
                "DE", "BE", "EL", "LT", "PT", "BG", "ES", "LU", "RO",
                "SI", "DK", "HR", "MT", "SK", "IT", "NL", "FI", "SE",
                "IE", "LV", "PL", "CY", "EE", "FR", "HU", "CZ", "AT"
            ];
            let cCode = window.localStorage.getItem("usr_country_code");
            // if (window.location.hostname === 'localhost') cCode = "DE";
            let displayed = window.localStorage.getItem("usr_info_displayed");
            if (!displayed) {
                this.setState({setReload: true});
                if (!in_array(cCode, euCountries)) {
                    window.localStorage.setItem("usr_info_displayed", "true");
                    this.setState({openGeoInfoModal: true});
                }
            }
        }, 2000);
    }

    reload() {
        if (this.state.setReload) {
            if (document) {
                if (document.URL) {
                    let urlParts = document.URL.split("/"), len = 0;
                    if (Array.isArray(urlParts) && (len = urlParts.length) > 0) {

                        let route = urlParts[(len - 1)];

                        if (route === "register") {
                            window.location.reload();
                        }
                    }
                }
            }
        }
        this.setState({openGeoInfoModal: false});
    }


    render() {

        return (
            <Modal show={this.state.openGeoInfoModal} size="md" style={{zIndex: 33333}}>
                <ModalContentGeoInfo onReloadClick={this.reload}></ModalContentGeoInfo>
            </Modal>
        )
    }
}

export default GeoInfo;
