import React from "react";
import PropTypes from "prop-types";
import koenigsErlaeuterungen from '../../assets/images/KE_logo.jpg'
import iconFB from '../../assets/images/fb.png'
import iconInsta from '../../assets/images/instagram.png'
import iconYoutube from '../../assets/images/youtube.png'
import {isMobile} from "../../utils/device";
import './mainFooter.css'

import {useSelector} from 'react-redux'

const MainFooter = ({copyright, transparent, transparentMobile}) => {

    const authentication = useSelector((state) => state.authentication);

    return (
        <footer id={transparentMobile || transparent ? "mainFooterTransparent" : "mainFooter"}
                className="container-fluid"
                style={{}}>
            <div style={{paddingTop: 10, paddingBottom: 20, paddingLeft: 30, paddingRight: 30, minHeight:200}}
                 className="row footer-container">
                <div className="links-section col-md-3 col-6">
                    <div className={"footerMenuItem"}>
                        <a href="/alle-arbeitshilfen/">
                            <span className={"footerMenuItem"}>Alle Arbeitshilfen</span>
                        </a>
                    </div>
                    <div className="footerMenuItem">
                        <a href="https://lektueren-verstehen.de/infothek/kontakt/">
                            <span className={"footerMenuItem"}>Kontakt</span>
                        </a>
                    </div>
                    <div className="footerMenuItem">
                        <a href="https://lektueren-verstehen.de/infothek/der-verlag/">
                            <span className={"footerMenuItem"}>&Uuml;ber uns</span>
                        </a>
                    </div>
                </div>
                <div className="links-section col-md-3 col-6">
                    <div className={"footerMenuItem"}>
                        <a href="https://lektueren-verstehen.de/infothek/faq/">
                            <span className={"footerMenuItem"}>FAQs</span>
                        </a>
                    </div>
                    <div className="footerMenuItem">
                        <a href="https://seu.cleverreach.com/f/10202-143200/" target="blank">
                            <span className={"footerMenuItem"}>Newsletter-Anmeldung</span>
                        </a>
                    </div>
                    {authentication.authenticated ? <div className="footerMenuItem">
                        <a href="/my/abos/">
                            <span className={"footerMenuItem"}>Abonnement verwalten</span>
                        </a>
                    </div> : <div className="footerMenuItem"/>
                    }
                </div>
                <div className="links-section col-md-3 col-6">
                    <div className="footerMenuItem">
                        <a href="https://lektueren-verstehen.de/infothek/impressum/ ">
                            <span className={"footerMenuItem"}>Impressum</span>
                        </a>
                    </div>

                    <div className="footerMenuItem">
                        <a href="https://lektueren-verstehen.de/infothek/datenschutz/ ">
                            <span className={"footerMenuItem"}>Datenschutz</span>
                        </a>
                    </div>
                    <div className="footerMenuItem">
                        <a href="https://lektueren-verstehen.de/infothek/agb/ ">
                            <span className={"footerMenuItem"}>AGB</span>
                        </a>
                    </div>
                </div>
                <div className="links-section col-md-3">
                    <div className="row">
                        <div className={"col-12 footerMenuItem" + (isMobile ? " text-right" : "")}>
                            Folge uns auf:
                        </div>
                        <div className={"col-12 footerMenuItem" + (isMobile ? " text-right" : "")}>
                            <div className="row">
                                <div className="col-4 footerMenuItem">
                                    <a rel="noopener noreferrer"
                                       href="https://www.facebook.com/KoenigsErlaeuterungen/"
                                       title="Könings Erläuterungen bei Facebook" target="_blank">
                                        <img alt="facebook"
                                             style={{
                                                 width: "29px",
                                                 height: "29px",
                                                 cursor: "pointer"
                                             }} src={iconFB}
                                             className="socialIcon mr-3"/></a>
                                </div>
                                <div className="col-4 footerMenuItem">
                                    <a rel="noopener noreferrer"
                                       href="https://www.instagram.com/koenigserlaeuterungen/"
                                       title="Könings Erläuterungen bei instagram" target="_blank">
                                        <img alt="instagram"
                                             style={{
                                                 width: "28px",
                                                 height: "29px",
                                                 cursor: "pointer"
                                             }}
                                             src={iconInsta}
                                             className="socialIcon mr-3"/></a>
                                </div>
                                <div className="col-4 footerMenuItem">
                                    <a rel="noopener noreferrer"
                                       href="https://www.youtube.com/channel/UCRKVAzRDNve2SlMBEDH5cvA"
                                       title="Könings Erläuterungen bei YouTube" target="_blank">
                                        <img alt="youtube"
                                             style={{
                                                 width: "33px",
                                                 height: "29px",
                                                 cursor: "pointer"
                                             }}
                                             src={iconYoutube}
                                             className="socialIcon mr-3"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row footerMenuItem"}>
                        <div className="col-12 text-right mt-2">
                            <a href="https://www.koenigs-erlaeuterungen.de/" target="blank">
                                <img alt="koenigsErlaeuterungen" src={koenigsErlaeuterungen}
                                     style={{maxWidth: "200px"}}/>
                            </a>
                            <div style={{color: "lightgrey"}}>{copyright}</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}


MainFooter.propTypes = {
    /**
     * Whether the content is contained, or not.
     */
    contained: PropTypes.bool,
    /**
     * The menu items array.
     */
    menuItems: PropTypes.array,
    /**
     * The copyright info.
     */
    copyright: PropTypes.string
};
const currentyear = new Date().getFullYear();
MainFooter.defaultProps = {
    contained: false,
    copyright: "© " + currentyear + " C.Bange Verlag GmbH"
};

export default MainFooter;
