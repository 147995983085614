import React from 'react';
import PropTypes from 'prop-types';

import image from '../../assets/images/image.svg';
import audio from '../../assets/images/audio.svg';
import video from '../../assets/images/video.svg';
import text from '../../assets/images/text.svg';

import TitleAndAuthor from '../titleAndAuthor/TitleAndAuthor'
import Button from '../button/Button'
import './BookPreviewCard.css'
import mockImage from '../../assets/images/coverSample.jpg';
import {isMobile} from "../../utils/device";
import apiAddress from "../../services/api/apiAddress"
import Lottie from 'lottie-react-web';
import Tooltip from '@material-ui/core/Tooltip';
import loader from '../../assets/images/loader.json';
import {addSlashes} from "../../modules/Functions";

class BookPreviewCard extends React.Component {

    constructor(props) {
        super(props);

        this.mediaImageURLs = this.parseMediaTypes(props.mediaTypes)
        this.imageContainer = React.createRef();
    }

    parseMediaTypes(mediaTypes) {
        let mediaImageURLs = [];

        for (let mediaType in mediaTypes) {
            switch (mediaTypes[mediaType]) {
                case "image":
                    mediaImageURLs.push(image);
                    break;
                case "video":
                    mediaImageURLs.push(video);
                    break;
                case "audio":
                    mediaImageURLs.push(audio);
                    break;
                case "text":
                    mediaImageURLs.push(text);
                    break;
                default:
                    break;
            }
        }

        return mediaImageURLs;
    }

    renderMediaItems() {

    }

    componentDidMount() {
        const {coverURL, isbn} = this.props;
        // Load the image asynchronously
        var url;
        if (coverURL) {
            url = coverURL;
        } else url = apiAddress + "/api/v1/content/books/media/" + isbn + "/Images/cover.jpg";
        if (!url) {
            url = mockImage;
        }

        var image = new Image();
        image.src = url;
        image.className = "bookPreviewCardLeftSide";
        image.height = 170;
        image.style = "cursor:pointer";
        image.alt = this.props.title;
        image.addEventListener("load", () => {
            if (this.imageContainer.current) {
                this.imageContainer.current.innerHTML = "";
                this.imageContainer.current.appendChild(image);
            }
        });
    }

    render() {
        if (isMobile) {
            return this.renderMobile();
        }
        let {title, author, oAuthor, description, price, link, animate, couponcode} = this.props;

        link = addSlashes(link);

        if (animate === false) {
            return (
                <a href={link} style={{textDecoration: "none", outline: "none"}}>
                    <div className="bookPreviewCardContainer">
                        <div ref={this.imageContainer} style={{height: "170px", width: "122px"}}>
                            <Lottie style={{width: "20px"}}
                                    options={{
                                        animationData: loader,
                                    }}
                            />
                            {/* <img alt="bookPreviewCardLeftSide" style={{ cursor: "pointer" }} className="bookPreviewCardLeftSide" src={url} height="170px" /> */}
                        </div>
                        <div className="bookPreviewCardRightSide">
                            <div className="bookPreviewCardRightSideMainContent">
                                <TitleAndAuthor onClick={() => {
                                    window.location.href = link
                                }} title={title} author={author} oAuthor={oAuthor}/>
                                {this.mediaImageURLs.length > 0 &&
                                <div className="bookPreviewCardMediaTypesContainer mb-2">
                                    {this.mediaImageURLs.map((mediaImageURL, index) => {
                                        let fileType = mediaImageURL.split("/").pop().split(".")[0];
                                        let mediaType;
                                        switch (fileType) {
                                            case "image":
                                                mediaType = "Bild";
                                                break;
                                            case "audio":
                                                mediaType = "Audio";
                                                break;
                                            case "video":
                                                mediaType = "Video";
                                                break;
                                            case "text":
                                                mediaType = "Text";
                                                break;
                                            default:
                                                break;
                                        }
                                        return <Tooltip title={mediaType} aria-label={mediaType}>
                                            <img key={index} alt="mediaImageURL" src={mediaImageURL}/>
                                        </Tooltip>
                                    })}
                                </div>}
                                <div className="bookPreviewCardDescription mb-md-2">{description}</div>
                            </div>
                            {!price && <Button link={link} colorScheme="classic" size="small">Zum Titel</Button>}
                            {price && <div>{price}</div>}
                        </div>
                        {couponcode && <div className={'bookpreviewcoupon'}>E-Book-Code:<br/>{couponcode}</div>}
                    </div>
                </a>
            )
        }

        return (
            <a href={link} style={{textDecoration: "none", outline: "none"}}>
                <div aos-init="true" aos-animate="true" data-aos="fade-up" data-aos-duration={1000}
                     className="bookPreviewCardContainer">
                    <div ref={this.imageContainer} style={{height: "170px", width: "122px"}}>
                        <Lottie style={{width: "20px"}}
                                options={{
                                    animationData: loader,
                                }}
                        />
                        {/* <img alt="bookPreviewCardLeftSide" style={{ cursor: "pointer" }} className="bookPreviewCardLeftSide" src={url} height="170px" /> */}
                    </div>
                    <div className="bookPreviewCardRightSide">
                        <div className="bookPreviewCardRightSideMainContent">
                            <TitleAndAuthor title={title} author={author} oAuthor={oAuthor}/>
                            {this.mediaImageURLs.length > 0 &&
                            <div className="bookPreviewCardMediaTypesContainer mb-2">
                                {this.mediaImageURLs.map((mediaImageURL, index) => {
                                    let fileType = mediaImageURL.split("/").pop().split(".")[0];
                                    let mediaType;
                                    switch (fileType) {
                                        case "image":
                                            mediaType = "Bild";
                                            break;
                                        case "audio":
                                            mediaType = "Audio";
                                            break;
                                        case "video":
                                            mediaType = "Video";
                                            break;
                                        case "text":
                                            mediaType = "Text";
                                            break;
                                        default:
                                            break;
                                    }
                                    return <Tooltip key={index} title={mediaType} aria-label={mediaType}>
                                        <img alt="mediaImageURL" src={mediaImageURL}/>
                                    </Tooltip>
                                })}
                            </div>}
                            <div className="bookPreviewCardDescription mb-md-2">{description}</div>
                        </div>
                        {/*eslint-disable-next-line*/}
                        {!price && <Button colorScheme="classic" size="small">Zum Titel</Button>}
                        {price && <div>{price}</div>}
                    </div>
                    {couponcode && <div className={'bookpreviewcoupon'}>E-Book-Code:<br/>{couponcode}</div>}
                </div>
            </a>

        )

    }

    renderMobile() {
        let {title, author, oAuthor, description, price, link, animate, couponcode} = this.props;
        link = addSlashes(link);
        // change back
        if (animate === false) {
            return (
                <div className="bookPreviewCardContainerMobile">
                    <a href={link} style={{textDecoration: "none", outline: "none"}}>
                        <div ref={this.imageContainer} style={{height: "170px", width: "122px"}}>
                            <Lottie style={{width: "20px"}}
                                    options={{
                                        animationData: loader,
                                    }}
                            />
                            {/* <img alt="bookPreviewCardLeftSide" style={{ cursor: "pointer" }} className="bookPreviewCardLeftSide" src={url} height="170px" /> */}
                        </div>
                    </a>
                    <div className="bookPreviewCardRightSide">
                        <a href={link} style={{textDecoration: "none", outline: "none"}}>
                            <div className="bookPreviewCardRightSideMainContent">
                                <TitleAndAuthor title={title} author={author} oAuthor={oAuthor}/>
                                {this.mediaImageURLs.length > 0 &&
                                <div className="bookPreviewCardMediaTypesContainer mb-2">
                                    {this.mediaImageURLs.map((mediaImageURL) => {
                                        return <img alt="mediaImageURL" src={mediaImageURL}/>
                                    })}
                                </div>}
                                <div className="bookPreviewCardDescription mb-md-2">{description}</div>
                            </div>
                        </a>
                        {!price && <Button link={link} colorScheme="classic" size="small">Zum Titel</Button>}
                        {price && <div>{price}</div>}
                    </div>
                    {couponcode && <div className={'bookpreviewcoupon'}>E-Book-Code:<br/>{couponcode}</div>}
                </div>
            )
        }

        return (

            <div aos-init="true" aos-animate="true" data-aos="fade-up" data-aos-duration={1000}
                 className="bookPreviewCardContainerMobile">
                <a href={link} style={{textDecoration: "none", outline: "none"}}>
                    <div ref={this.imageContainer} style={{height: "170px", width: "122px"}}>
                        <Lottie style={{width: "20px"}}
                                options={{
                                    animationData: loader,
                                }}
                        />
                        {/* <img alt="bookPreviewCardLeftSide" style={{ cursor: "pointer" }} className="bookPreviewCardLeftSide" src={url} height="170px" /> */}
                    </div>
                </a>
                <div className="bookPreviewCardRightSide">
                    <a href={link} style={{textDecoration: "none", outline: "none"}}>
                        <div className="bookPreviewCardRightSideMainContent">
                            <TitleAndAuthor title={title} author={author} oAuthor={oAuthor}/>
                            {this.mediaImageURLs.length > 0 &&
                            <div className="bookPreviewCardMediaTypesContainer mb-2">
                                {this.mediaImageURLs.map((mediaImageURL, index) => {
                                    return <img key={index} alt="mediaImageURL" src={mediaImageURL}/>
                                })}
                            </div>}
                            <div className="bookPreviewCardDescription mb-md-2">{description}</div>
                        </div>
                    </a>
                    {!price && <Button link={link} colorScheme="classic" size="small">Zum Titel</Button>}
                    {price && <div>{price}</div>}
                </div>
                {couponcode && <div className={'bookpreviewcoupon'}>E-Book-Code:<br/>{couponcode}</div>}
            </div>
        )
    }
}

BookPreviewCard.propTypes = {
    title: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    oAuthor: PropTypes.string,
    description: PropTypes.string,
    mediaTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
    coverURL: PropTypes.string
}

export default BookPreviewCard;
