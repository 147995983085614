import React from "react";

//STYLING
import './MyAbos.css'

//GRAPHICAL COMPONENTS

import Button from '../componentsBange/button/Button'
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {
     executePayment
} from '../reducers/payment/actions';
import { fetchBookById } from "../reducers/content/actions";
import animationData from '../assets/images/animation.json';
import Lottie from 'lottie-react-web'
import loader from '../assets/images/loader.json';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
class CheckoutPurchase extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            orderSuccess: false,
            checkoutState: "",
            loading: true
        }

    }

    componentDidMount() {
        // from parent component
        let paymentId = this.props.paymentId;
        // get updated payment resource after client entered details on paypal page and gets redirected, contains approvalURL for paypalplus iFrame
        this.setState({ loading: true });
        this.props.executePayment(paymentId, this.executedPaymentSuccess, this.executedPaymentError);
    }

    componentDidUpdate(prevProps) {
        // recieved payment resource
        if (prevProps.payment !== this.props.payment) {
            console.log("got payment resource details ", this.props.payment);
            // payment state is gonna be created for all payment methods
            // get book details via BookId to show information to customer
            let bookId = this.props.payment.transactions[0].custom; // TODO, what if multiple books
            this.props.fetchBookById(bookId);
        }
    }

    gotPaymentResourceSuccess = () => {
        console.log("gotPaymentResourceSuccess");
        this.setState({ checkoutState: "preExecution" });
        // got payment resource, now execute it 
        this.props.executePayment(this.props.paymentId, this.props.payment.payer.payer_info.payer_id, this.executePaymentSuccess, this.executePaymentError);
    }

    gotPaymentResourceError = () => {
        console.log("gotPaymentResourceError");
        this.setState({ loading: false, checkoutState: "noPaymentResource" });
    }

    executedPaymentSuccess = () => {
        console.log("should be ordered now ");
        this.setState({ checkoutState: "afterExecution", loading: false, orderSuccess: true })
        //this.props.getSale(this.props.paymentExecution.transactions[0].related_resources[0].sale.id,"clientid", this.getSaleSuccess,this.getSaleError);
    }

    executedPaymentError = (errorCode) => {
        switch (errorCode) {
            // could not get payment resource
            case 10:
                this.setState({ checkoutState: "noPaymentResource", loading: false });
                this.props.onError();
                break;
            // could not get sale 
            case 20:
                this.setState({ checkoutState: "noPaymentResource", loading: false });
                this.props.onError();
                break;
            // book already ordered 
            // --> unlock animation 
            case 30:
                this.setState({ checkoutState: "alreadyOrdererd", loading: false})
                this.props.onError();
                break;
            // payment execution went wrong
            case 40:
                this.setState({ checkoutState: "executionFailure", loading: false})
                this.props.onError();
                break;
            // timeout
            // --> retry
            default: 
            console.log("retrying");
                let paymentId = this.props.paymentId;
                // get updated payment resource after client entered details on paypal page and gets redirected, contains approvalURL for paypalplus iFrame
                this.setState({ loading: true });
                this.props.executePayment(paymentId, this.executedPaymentSuccess, this.executedPaymentError);
            break;
        }
    }


    render() {
 /*        if (isMobile) {
            this.renderMobile();
        } */
        return (
            <div className="modalContainer">
                <div>
                    <div className="headingStyle" style={{ fontSize: "1.8em", lineHeight: "1.0em", paddingTop: 20 }}>{(!this.state.loading && this.state.orderSuccess) ? ("Kauf abgeschlossen") : ("Kauf abschließen")}</div>
                </div>
                {/* Loader */}
                {this.state.loading && (<Lottie style={{ width: "20%",marginTop:"30px" }}
                    options={{
                        animationData: loader,
                    }}
                />)}
                {/* If could not fetch paymentResource from paypal due to e.g. invalid paymentID */}
                {this.state.checkoutState === "noPaymentResource" && (
                    <div>
                        <div style={{ fontSize: "1.15em" }} className="my-2">
                            PaymentID nicht gültig oder abgelaufen, bitte Vorgang wiederholen.
                        </div>
                        <Button colorScheme="inverted" size="small" onClick={() => { this.props.onClose() }}>Schließen</Button>
                    </div>
                )}
                {/* If payment is not approved or created , execution error*/}
                {this.state.checkoutState === "executionFailure" && (
                    <div>
                        <div style={{ fontSize: "1.15em" }} className="my-2">Der Bezahlvorgang konnte nicht abgeschlossen werden. Bitte erneut versuchen und ggf. die Zahlungsdaten überprüfen.</div>
                        <Button colorScheme="inverted" size="small" onClick={() => { this.props.onClose() }}>Schließen</Button>
                    </div>
                )}
                {/* User has already ordered the book */}
                {this.state.checkoutState === "alreadyOrdererd" && (
                    <div>
                        <div style={{ fontSize: "1.15em" }} className="my-2">Du hast diese Buch bereits gekauft. Der Bezahlvorgang wurde nicht abgeschlossen.</div>
                        <div className="mt-3 text-center">
                                <Button colorScheme="classic" size="large" onClick={() => { this.props.onClose() }}>Jetzt lesen <NavigateNextIcon style={{ color: "white", width: 40, height: 40 }} /></Button>
                            </div>
                    </div>
                )}
                {/* payment has been successfully executed on paypal api and our server */}
                {this.state.checkoutState === "afterExecution" && (
                    <div>
                        {!this.state.loading && this.state.orderSuccess && (<div className="text-center">
                            <Lottie style={{ width: "40%" }}
                                options={{
                                    animationData: animationData,
                                    loop: false,
                                }}
                            />
                            <div className="mt-3">
                                <Button colorScheme="classic"  size="large" onClick={() => { this.props.onClose() }}>Jetzt lesen <NavigateNextIcon style={{ color: "white", width: 40, height: 40 }} /></Button>
                            </div>

                        </div>
                        )}
                    </div>)}

            </div>
        )
    }

    renderMobile() {
        return (
            <div className="modalContainer">
                <div>
                    <div className="headingStyle" style={{ fontSize: "1.8em", lineHeight: "1.0em", paddingTop: 20 }}>{(!this.state.loading && this.state.orderSuccess) ? ("Kauf abgeschlossen") : ("Kauf abschließen")}</div>
                </div>
                {/* Customer approved on PayPal Page and now gets back to our page  
            {this.props.payment && this.state.checkoutState === "afterExecution" && this.state.orderSuccess && (
                <div>
                    <div className="row">
                        <div className="col-12 py-2" style={{ fontSize: "1.15em" }} >
                            Du hast jetzt Zugang zu allen Inhalten von "{this.props.currentBook.title}"
                        </div>
                        <div className="col-12 p-1">
                            {this.props.currentBook && this.props.payment && <BookPreviewCardAlternative price={this.props.price} title={this.props.currentBook.title} author={this.props.currentBook.author} animate={false} mediaTypes={["image"]} description={this.props.currentBook.uTitle} />}
                        </div>
                    </div>
                </div>)}*/}
                {/* If could not fetch paymentResource from paypal due to e.g. invalid paymentID 
            {this.state.checkoutState === "noPaymentResource" && (
                <div style={{ fontSize: "1.15em" }}>
                    Keine gültige PaymentID, bitte Vorgang wiederholen
                    </div>
            )}*/}
                {/* If payment is not approved or created 
            {this.props.payment && this.state.checkoutState === "preExecution" && (this.props.payment.state !== "created" && this.props.payment.state !== "approved") && (
                <div style={{ fontSize: "1.15em" }}>
                    Beim Erstellen der Transaktion ist etwas schief gelaufen. Bitte erneut versuchen
                    </div>
            )}*/}
                {/* payment is  approved or created,enable button for payment execution */}
                {/*this.props.payment && this.state.checkoutState === "preExecution" && !this.state.loading && (this.props.payment.state === "created" || this.props.payment.state === "approved") &&
                <div className="my-2">
                    <Button className="mr-2" colorScheme="classNameic" size="medium" onClick={() => { this.props.executePayment(this.props.payment.id, this.props.payment.payer.payer_info.payer_id, this.executePaymentSuccess, this.executePaymentError); this.setState({ loading: true }); }}>Jetzt für {(this.props.payment && this.props.payment.transactions[0].item_list.items[0].price) + " " + (this.props.payment && this.props.payment.transactions[0].item_list.items[0].currency)} Kaufen</Button>
                </div>*/}
                {this.state.loading && (<Lottie style={{ width: "20%" }}
                    options={{
                        animationData: loader,
                    }}
                />)}
                {/* payment has been successfully executed on paypal api and our server */}
                {this.state.checkoutState === "afterExecution" && (
                    <div>
                        {!this.state.loading && this.state.orderSuccess && (<div className="text-center">
                            <Lottie style={{ width: "40%" }}
                                options={{
                                    animationData: animationData,
                                    loop: false,
                                }}
                            />
                            <div className="mt-3">
                                <Button colorScheme="classic" style={{maxWidth:"100%"}} size="large" onClick={() => { this.props.onClose() }}>Jetzt lesen <NavigateNextIcon style={{ color: "white", width: 40, height: 40 }} /></Button>
                            </div>

                        </div>
                        )}
                        {/* payment execution has failed*/}
                        {!this.state.loading && !this.state.orderSuccess && (
                            <div>
                                <div style={{ fontSize: "1.15em" }} className="mb-2">Der Bezahlvorgang konnte nicht abgeschlossen werden. Bitte erneut versuchen </div>
                                <Button colorScheme="inverted" size="small" onClick={() => { this.props.onClose() }}>Schließen</Button>
                            </div>)}



                    </div>)}

            </div>
        )
    }
}
const mapStateToProps = state => ({
    //currentBook: state.content.currentBook
});

const mapDispatchToProps = dispatch => ({
    executePayment: (paymentid, successCb, errorCb) => dispatch(executePayment(paymentid, successCb, errorCb)),
    fetchBookById: (bookId, successCb, errorCb) => dispatch(fetchBookById(bookId, successCb, errorCb))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutPurchase));


