exports.API_GETIP_URL = (() => {
    let dynURL;
    if (typeof document != "undefined") {
        if ((dynURL = document.URL)) {
            if (dynURL.search(/dev\.lektueren-verstehen\.de/) >= 0)
                return "https://dev.lektueren-verstehen.de/geoip/getIP.php";
            if (dynURL.search(/localhost/) >= 0)
                // duplikat aus "shop" bzw public->build von lektueren-verstehen
                return "https://local.bangesw6.de/geoip/getIP.php";
        }
    }
    return "https://lektueren-verstehen.de/geoip/getIP.php";
})();
