import React  from 'react';
import RegisterForm from '../RegisterForm/RegisterForm';

    class ModalContentBuyCheckout extends React.Component {


        constructor(props) {
          super(props);
          this.state = {
            };
        }

    render(){
        return(
          <div class="modalContainer">
            <RegisterForm registerSuccessCallback={()=>this.props.onRegisterSuccess()}/>
          </div>
                 )

        }

}

export default ModalContentBuyCheckout;
