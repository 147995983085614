
export var isMobile = false;
export default function mobileAndTabletcheck() {

  isMobile = (window.innerWidth < 992);

}

export function loadThridPartyCookies() {
  // hotjar
  let script = document.createElement("script");
  let code = `(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:1674708,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
   })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

  let text = document.createTextNode(code);
  script.appendChild(text);
  script.async = true;
  document.body.appendChild(script);
  //console.log(script,"appended");


  script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=UA-2553159-5";
  text = document.createTextNode(code);
  script.appendChild(text);
  script.async = true;
  document.body.appendChild(script);
  //console.log(script, "appended");

  script = document.createElement("script");
  code = ` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-2553159-5');`;

  text = document.createTextNode(code);
  script.appendChild(text);
  script.async = true;
  document.body.appendChild(script);
  //console.log(script, "appended");


}

export function cookieCheck() {
  // check if cookies are enabled
  // console.log("pre check");
  let cookieVal = getCookie("cookieBange");
  if (cookieVal !== "" && cookieVal === "true") {
    loadThridPartyCookies();
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
