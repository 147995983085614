import React from 'react';
import PropTypes from 'prop-types';

import './Heading.css';
import {isMobile} from "../../utils/device";

function Heading({type, children}) {
    if (isMobile) {
        return (
            <div className={'headingStyle ' + type+"mobile"}>{children}</div>
        )
    }
    return (
        <div className={'headingStyle ' + type}>{children}</div>
    )
}

Heading.proptTypes = {
    type: PropTypes.oneOf(["primary", "secondary", "tertiary"]).isRequired,
    children: PropTypes.node.isRequired
}



export default Heading;
