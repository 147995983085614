
import { lazy} from "react";
// Layout Types

import { DefaultLayout,ViewerLayout} from "./layouts";
import AlleArbeitshilfen from "./views/AlleArbeitshilfen";
import ByAuthor from "./views/ByAuthor";

// Route Views
const ForgotPassword = lazy(() => import('./views/ForgotPassword'));
const ChangePassword = lazy(() => import('./views/ChangePassword'));
const Landing = lazy(() => import('./views/Landing'));
const Register = lazy(() => import('./views/Register'));
const MyTitles = lazy(() => import('./views/MyTitles'));
const MyAbos = lazy(() => import('./views/MyAbos'));
const MyAccount = lazy(() => import('./views/MyAccount'));
const Search = lazy(() => import('./views/Search'));
const Admin = lazy(() => import('./views/Admin'));
const disableCookies = lazy(() => import('./views/disableCookies'));
const Page404 = lazy(() => import("./views/Page404"));
const Viewer = lazy(() => import('./views/Viewer'));
const AdminBooks = lazy(() => import('./views/AdminBooks'));
const AdminUsers = lazy(() => import('./views/AdminUsers'));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: Landing
  },
  {
    path: "/search",
    layout: DefaultLayout,
    component: Search
  },
  {
    path: "/register",
    layout: DefaultLayout,
    component: Register
  },
  {
    path: "/my/titles",
    exact: true,
    layout: DefaultLayout,
    component: MyTitles
  },
  {
    path: "/my/abos",
    exact: true,
    layout: DefaultLayout,
    component: MyAbos
  },
  {
    path: "/my/account",
    exact: true,
    layout: DefaultLayout,
    component: MyAccount
  },
  {
    path: "/change-password/:token",
    layout: DefaultLayout,
    component: ChangePassword
  },
  {
    path: "/alle-arbeitshilfen",
    layout: DefaultLayout,
    component: AlleArbeitshilfen
  },
  {
    path: "/:author/:title/:id",
    layout: ViewerLayout,
    component:  Viewer
  },
  {
    path: "/:author/:title",
    layout: ViewerLayout,
    component: Viewer
  },
  {
    path: "/forgot-password",
    layout: DefaultLayout,
    component: ForgotPassword
  },
  {
    path: "/admin",
    layout: DefaultLayout,
    component: Admin
  },
  {
    path: "/adminbooks",
    layout: DefaultLayout,
    component: AdminBooks
  },
  {
    path: "/adminusers",
    layout: DefaultLayout,
    component: AdminUsers
  },
  {
    path: "/impressum",
    layout: DefaultLayout,
    component: Page404,
    status: 404
  },
  {
    path: "/faq",
    layout: DefaultLayout,
    component: Page404,
    status: 404
  },
  {
    path: "/agbs",
    layout: DefaultLayout,
    component: Page404,
    status: 404
  },
  {
    path: "/datenschutz",
    layout: DefaultLayout,
    component: Page404,
    status: 404
  },
  {
    path: "/ke",
    layout: DefaultLayout,
    component: Page404,
    status: 404
  },
  {
    path: "/disablecookies",
    layout: DefaultLayout,
    component: disableCookies
  },
  {
    path: "/:author",
    layout: DefaultLayout,
    component: ByAuthor
  },
  {
    path: "*",
    layout: DefaultLayout,
    component: Page404,
    status: 404
  },
];
