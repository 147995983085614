import React, {Suspense} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Lottie from 'lottie-react-web'
import './assets/Fonts/Montserrat.css';
import loader from './assets/images/loader.json';

import {in_array} from './modules/Functions';

import routes from "./routes";
import {Provider} from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.css";
import Https from "./services/api/apiClient";

import configureStore from './store';

import {PersistGate} from 'redux-persist/integration/react'
import mobileAndTabletcheck from "./utils/device";
import {cookieCheck} from './utils/device';
import GeoInfo from './componentsBange/GeoInfo';

import APIGet from './services/api/apiGet';

import AOS from 'aos';
import 'aos/dist/aos.css';
import {API_GETIP_URL} from "./getIP.config";
// TURNING OFF ALL CONSOLE LOGS
//console.log = console.warn = console.error = () => {};

const {store, persistor} = configureStore();
// init animation library
AOS.init();
// check for mobile
mobileAndTabletcheck();
// check for cookies to set 
cookieCheck();
const renderLoader = () => {
    return (<div style={{position: "fixed", left: "50%", top: "50%", marginLeft: "-35px"}}>
            {/*eslint-disable-next-line*/}
            <Lottie
                width={70}
                options={{
                    animationData: loader,
                }}
            /></div>
    )
};

/**
 * check geoIP
 * - set localStorage (usr_country_code, usr_is_eu, usr_ip)
 *
 * @return {Promise<boolean>}
 */
const getCountryCode = async () => {
    let euCountries = [
        "DE", "BE", "EL", "LT", "PT", "BG", "ES", "LU", "RO",
        "SI", "DK", "HR", "MT", "SK", "IT", "NL", "FI", "SE",
        "IE", "LV", "PL", "CY", "EE", "FR", "HU", "CZ", "AT"
    ];
    let cCode = window.localStorage.getItem("usr_country_code");
    let date = window.localStorage.getItem("usr_current_time");
    if (date) {
        const maxAge = 86400000 * 5; // 5 tage n millisekunden
        let diff = Date.now() - new Date(date).getTime();
        if (diff > maxAge) {
            window.localStorage.removeItem("usr_country_code");
            window.localStorage.removeItem("usr_is_eu")
            window.localStorage.removeItem("usr_ip");
            window.localStorage.removeItem("usr_country_name");
            window.localStorage.removeItem("usr_city");
            window.localStorage.removeItem("usr_current_time");
            cCode = null;
        }
    }

    if (!cCode) {
        let result = await APIGet(API_GETIP_URL);

        if (typeof result === "object") {
            if (result.status && result.ip) {
                if (typeof result === "object") {
                    if (result.hasOwnProperty('location') &&
                        result.location.hasOwnProperty('country') &&
                        result.location.country.hasOwnProperty('code')) {

                        window.localStorage.setItem("usr_country_code", result.location.country.code);
                        window.localStorage.setItem("usr_is_eu", in_array(result.location.country.code.toUpperCase(), euCountries) ? "TRUE" : "FALSE");
                        window.localStorage.setItem("usr_ip", result.ip);
                        let wCountry = "NULL";
                        if (result.location.country.code === "DE") {
                            wCountry = "Deutschland";
                        } else {
                            if (result.location.country.name)
                                wCountry = result.location.country.name;
                        }
                        window.localStorage.setItem("usr_country_name", wCountry);
                        let wCity = "";
                        if (result.location.city) wCity = result.location.city;
                        window.localStorage.setItem("usr_city", wCity);

                    }
                    if (result.hasOwnProperty('time_zone') &&
                        result.time_zone.hasOwnProperty('current_time')) {
                        if (result.time_zone.current_time)
                            window.localStorage.setItem("usr_current_time", result.time_zone.current_time);
                    }
                }
            }
        }
    }
    return true;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    getCountryCode().then(r => {
    });

    return (
        <Provider store={store}>
            <PersistGate
                onBeforeLift={() => {
                    Https.setAccessToken(store.getState().authentication.accessToken)
                }}
                persistor={persistor}>
                <Router basename={process.env.REACT_APP_BASENAME || ""}>
                    <Suspense fallback={renderLoader()}>
                        <GeoInfo/>
                        <Switch>
                            {routes.map((route, index) => {
                                return (
                                    <Route
                                        status={(route.status && route.status === 404) ? 404 : 200}
                                        key={index}
                                        path={route.path}
                                        exact
                                        component={props => {
                                            return (
                                                <route.layout {...props}>
                                                    <route.component {...props} />
                                                </route.layout>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </Suspense>
                </Router>
            </PersistGate>
        </Provider>
    );
}
