import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistCombineReducers } from 'redux-persist'

import authentication from "./reducers/authentication/reducer";
import user from "./reducers/user/reducer";
import content from "./reducers/content/reducer";
import payment from "./reducers/payment/reducer";
import products from "./reducers/products/reducer";
import storage from "redux-persist/es/storage";
import codes from "./reducers/codes/reducer";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication']
};
/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const persistedReducer = persistCombineReducers(persistConfig, {
  authentication,
  user,
  products,
  content,
  payment,
  codes
});

/**
 * configureStore returns a new store
 * @param  {Object} initialState the initial state
 * @return {Object}              the store
 */
export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );
  const persistor = persistStore(store);
  return { store, persistor}
}
