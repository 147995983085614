import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";

export default class AccountBox extends Component {

    constructor(props) {
        super(props);
        this.accBox = null;
    }

    componentDidMount() {
        // console.log("AccountBox mount");
        document.body.addEventListener('click', this.onBodyClicked);
    }

    onBodyClicked = (event) => {
        // console.log(event.target.id, "event");
        // let loginBoxId = document.getElementById("#loginBox");
        // console.log(this.accBox);
        //c onsole.log(accBoxId);
        if (((this.accBox) && this.accBox.contains(event.target)) || (event.target.id && event.target.id === "accountIcon")) {
            // console.log("‘clicked inside’");
        } else {
            this.props.onClickOutside();
            // console.log("‘clicked outside");
        }
    }

    componentWillUnmount() {
        // console.log("unmoount");
        document.body.removeEventListener('click', this.onBodyClicked);
    }


    render() {
        return (
            <div ref={(ref) => {
                this.accBox = ref
            }} className={`account-box pb-1 ${this.props.admin ? "admin" : ""}`}>
                <div style={{marginTop: 10, marginLeft: 15, marginRight: 15}} class="d-flex flex-column">
                    <div class="row my-auto">
                        <div class="col-12">
                            <Link className={"accountBoxMenuItem"} to={"/my/account"}>Mein Account</Link>
                        </div>
                    </div>
                    <div class="row my-auto">
                        <div class="col-12">
                            <Link className={"accountBoxMenuItem"} to={"/my/titles"}>Meine Titel</Link>
                        </div>
                    </div>
                    <div class="row my-auto">
                        <div class="col-12">
                            <Link className={"accountBoxMenuItem"} to={"/my/abos"}>Mein Abonnement</Link>
                        </div>
                    </div>
                    {this.props.admin && <Fragment>
                        <div className="row my-auto">
                            <div className="col-12">
                                <Link className={"accountBoxMenuItem"} to={"/adminbooks"}>Administration Bücher</Link>
                            </div>
                        </div>
                        <div className="row my-auto">
                            <div className="col-12">
                                <Link className={"accountBoxMenuItem"} to={"/adminusers"}>Administration
                                    Benutzer</Link>
                            </div>
                        </div>
                    </Fragment>}
                    <div class="row my-auto">
                        <div class="col-12">
                            <Link className={"accountBoxMenuItem"} style={{color: "red"}} to={"/"} onClick={() => {
                                this.props.onLogout();
                            }}>Abmelden</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
