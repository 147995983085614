import React , {Fragment} from 'react';
import Heading from "../heading/Heading";
import Button from "../button/Button";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
  createSubscription
} from '../../reducers/payment/actions';
import '../ModalContentBuyCheckout/modalcontentbuycheckout.css';
import Lottie from 'lottie-react-web'
import loader from '../../assets/images/loader.json';
import {isMobile} from "../../utils/device";

export var Iframe = React.Component({
  render: function () {
    return (
      <div>
        <iframe title="paypalIframe" src={this.props.src} height={this.props.height} width={this.props.width} />
      </div>
    )
  }
});

class ModalContentAboCheckout extends React.Component {


  constructor(props) {
    super(props);
    this.state = { loading: false, myFrame: "" , loadingText: "Ihre Anfrage wird bearbeitet",errorText:""};
  }

  componentDidMount() {
    // load script for ppp
    const script = document.createElement("script");

    script.src = "https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js";
    //script.scr = "https://www.paypalobjects.com/webstatic/ppplusdcc/ppplusdcc.min.js";
    //script.async = true;

    document.body.appendChild(script);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscription !== this.props.subscription) {
      // payment resource changed, render paywall iframe
      console.log(this.props.subscription);
      /*  not working with approval url
       var ppp = window.PAYPAL.apps.PPP({
         "approvalUrl": this.props.subscription.links[0].href,
         "placeholder": "ppplus",
         "mode": "sandbox",
         "country": "DE"
         }); */
      // TODO: try and put link in iFrame
      if (this.props.subscription.links[0].href !== null && this.props.subscription.links[0].href !== undefined) {
        // this.setState({loading:false, myFrame: this.props.subscription.links[0].href});
        window.location.href = this.props.subscription.links[0].href;
        return null;
      } else this.setState({ loading: false });

    }
  }


  render() {
    if (isMobile) {
      return this.renderMobile();
    }
    return (
      <div className="modalContainer modalabocheckout">
        <div>

          <Heading type="secondary">Zahlung bestätigen</Heading>
        </div>
        <div className="pt-3" style={{ fontSize: "1.15em" }}>
          Mit dem Klick auf „Bestätigen“ schließen Sie ein Abonnement für das Königs-Erläuterungen-Portal ab.
          Sie können das Abonnement jederzeit monatlich kündigen.
                    <p>Zum Abschluss des Kaufprozesses wird auf PayPal weitergeleitet.
Es wird kein PayPal-Konto benötigt.
          Die Datensicherheit wird durch die SSL-Verschlüsselung gewährleistet.</p>
        </div>
        {this.state.loading && (
          <Fragment>
            <Lottie style={{ width: "20%" }}
              options={{
                animationData: loader,
              }}
            />
            <div className="text-center">{this.state.loadingText}</div>
          </Fragment>
        )}
        <div ref={(ref) => this.myframe = ref}>

        </div>
        <div >
        <Button colorScheme="inverted" size="medium" onClick={this.props.onBack}>Zurück</Button>
          {!this.state.loading && this.state.myFrame !== "" && <iframe title="paypalIframe" src={this.state.myFrame} height="500" width="500" />}
          {!this.state.loading && <div className="ml-2 d-inline-block"><Button colorScheme="classic" size="medium" onClick={() => { this.setState({ loading: true,errorText:'' }); this.props.createSubscription("planID", null, this.successCreateSubription, this.errorCreateSubscription.bind(this)) }}>Bestätigen</Button></div>}
        </div>
          {!this.state.loading && this.state.errorText ? <div className={'errorText'}>{this.state.errorText}</div>:null}
      </div>
    )

  }

  renderMobile() {
    return (
      <div className="modalContainer modalabocheckout isMobile">
        <div>
          <div className="headingStyle" style={{fontSize:"1.8em",lineHeight:"1.0em",paddingTop:20}}>Bestätigen</div>
        </div>
        <div className="pt-3" style={{ fontSize: "1.15em" }}>
          Mit dem Klick auf „Bestätigen“ schließen Sie ein Abonnement für das Königs-Erläuterungen-Portal ab.
          Sie können das Abonnement jederzeit monatlich kündigen.
        <p>Zum Abschluss des Kaufprozesses wird auf PayPal weitergeleitet.Es wird kein PayPal-Konto benötigt.
          Die Datensicherheit wird durch die SSL-Verschlüsselung gewährleistet.</p>
        </div>
        {this.state.loading && (
          <Fragment>
            <Lottie style={{ width: "20%" }}
              options={{
                animationData: loader,
              }}
            />
            <div className="text-center">{this.state.loadingText}</div>
          </Fragment>
        )}
        <div ref={(ref) => this.myframe = ref}>

        </div>
        <div >
        {!this.state.loading && <Button colorScheme="classic" size="medium" onClick={() => { this.setState({ loading: true,errorText:'' }); this.props.createSubscription("planID", null, this.successCreateSubription, this.errorCreateSubscription.bind(this)) }}>Bestätigen</Button>}
        <div className="mt-2"><Button colorScheme="inverted" size="medium" onClick={this.props.onBack}>Zurück</Button></div>
          {!this.state.loading && this.state.myFrame !== "" && <iframe title="paypalIframe" src={this.state.myFrame} height="500" width="500" />}
          
        </div>
          {!this.state.loading && this.state.errorText ? <div className={'errorText'}>{this.state.errorText}</div>:null}
      </div>
    )
  }
    successCreateSubription(){
      console.log('successCreateSubription');
    }
    errorCreateSubscription() {
      console.log('errorCreateSubscription');
      this.setState({loading:false, errorText:'Fehler beim erstellen des Abonnements'});
    }

}

const mapStateToProps = state => ({
  subscription: state.payment.subRes,
  currentBook: state.content.currentBook,
});

const mapDispatchToProps = dispatch => ({
  createSubscription: (planId, bookid, successCb, errorCb) => dispatch(createSubscription(planId, bookid, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalContentAboCheckout));
