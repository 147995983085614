import Https from "../../services/api/apiClient";
import { empty } from "../../modules/Functions";
import axios from "axios";
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER = 'REGISTER_USER';

function validUserData(user){

  let requiredFields = ["lastname","firstname","street","streetnr","postal","city"];
  for( let i = 0, c = requiredFields.length; i < c; i++ ){
      if( !user.hasOwnProperty( requiredFields[i] ) )
        return false;
      
      if( empty( user[ requiredFields[i] ] ) )
        return false;
  }
  return true;
}

export function loginUser(email, password, successCB, errorCB) {
 // console.log("loginuser", email, password)
  return function (dispatch, getState) {
    Https.post('/api/v1/users/login', {email, password})
      .then( res => {
        // console.log('User successfully logged in - res: ', res);

        const { data: {token, user}} = res.data;
        // console.log("LOGIN user data: ", user);
        let redirectTo = false;
        if( typeof user === "object" ){

          if( !user.hasOwnProperty("country") ){
            // redirectTo = "/my/account?update=1";
            
            if( user._id ){
              // console.log("USER ID: ", user._id);

              const _countryCode = window.localStorage.getItem( "usr_country_code");
              const _currenttime = window.localStorage.getItem( "usr_current_time");
              const _ip = window.localStorage.getItem( "usr_ip");

              let updateData = {
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                id: user._id,
                country:_countryCode,
                currenttime:_currenttime,
                ip:_ip
              }
              // console.log("USER UPDATE DATA: ", updateData);
              setTimeout(()=>{
                Https.post('/api/v1/users/postUpdateProfile', updateData).then( 
                  res => {
                    console.log("PROFILE AUTO UPDATE SUCCESSFUL", res);
                  }
                ).catch(err => {
                  console.log("ERROR: AUTO UPDATE PROFILE", err);
                })
              }, 500);
            }
            
          }
          if( !validUserData(user) )
              redirectTo = "/my/account?update=1";       
        }
        dispatch({
          type: LOGIN_USER,
          token,
          user,
        });
        // set token in https library
        Https.setAccessToken(token);
        if (successCB){  
          successCB();
          //console.log(successCB);
        }
        if(redirectTo){
          setTimeout( function(){
            window.location.href = redirectTo;
          }, 1000);
        }
      })
      .catch(err => {
        console.log("err", err);
        if (errorCB) errorCB(err);
        console.log('Couldnt login user - ')
      })
  };
}

export function register(firstname, lastname, role, email, password,passwordValidation,schoolname,street,streetnr,postal,city, newsletter, countryCode, currenttime, ip, successCB, errorCB) {
  return function (dispatch, getState) {

    Https.post('/api/v1/users/postSignup', {firstname, lastname, role, email, password, passwordValidation,schoolname,street,streetnr,postal,city,newsletter, countryCode, currenttime, ip})
      .then( res => {
       const { data: {token, user}} = res.data;

        dispatch({
          type: LOGIN_USER,
          token,
          user,
        });
         // set token in https library
       Https.setAccessToken(token);
        // register mail for newsletter
        if (newsletter) {
            let bodyFormData = new FormData();
            bodyFormData.set('email', email);
            bodyFormData.set('cr_subunsubscribe', 'true')
            axios({
                method: 'post',
                url: 'https://seu.cleverreach.com/f/10202-143200/wcs/',
                data: bodyFormData,
                headers: {'Content-Type': 'multipart/form-data'}
            })
                .then(function (response) {
                    //handle success
                    console.log(response);
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        }
        if (successCB) successCB();
      })
      .catch(err => {
        try {
          if (errorCB) errorCB(err);
        } catch(err) {
          console.log("err handling err", err)
          if (errorCB) errorCB(err);
        }

      })
  };
}

/* export function confirmEmail( token, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/users/confirm', {token})
      .then( res => {
        console.log('User successfully logged in and mail confirmed- res: ', res);

        const { data: {token, user}} = res.data;

        dispatch({
          type: LOGIN_USER,
          token,
          user,
        });
        // set token in https library
       Https.setAccessToken(token);
        if (successCB)  successCB();
      })
      .catch(err => {
        console.log("err", err);
        const { response: {data}} = err;

        if (errorCB) errorCB(data);
        console.log('Couldnt login user - ', data)
      })
  };
} */

export function updateProfile(id,firstname, lastname, email,newsletter, street, streetnr, city, postal, country, successCB, errorCB) {
  console.log("in func", id,email,firstname,lastname,newsletter, street, streetnr, city, postal, country);

  return function (dispatch, getState) {

    Https.post('/api/v1/users/postUpdateProfile', {id,firstname, lastname,  email, newsletter, street, streetnr, city, postal, country})
      .then( res => {
        // console.log('User successfully updated in res: ', res);

        const { data: {token, user}} = res.data;

        dispatch({
          type: LOGIN_USER,
          token,
          user,
        });
        if (successCB) successCB();
      })
      .catch(err => {
        try {
          const { response: {data}} = err;
          if (errorCB) errorCB(data);
          console.log('Couldnt register user - ', data)
        } catch(err) {
          console.log("err handling err", err)
          if (errorCB) errorCB();
        }

      })
  };
}

export function deleteAccount(id,successCB,errorCB) {
  return function (dispatch, getState) {

    Https.post('/api/v1/users/postDeleteAccount', {id})
      .then( res => {
        console.log('Account has been deleted', res);

        
        if (successCB) successCB();
        dispatch(logoutUser());
      })
      .catch(err => {
        try {
          const { response: {data}} = err;
          if (errorCB) errorCB(data);
          console.log('Couldnt register user - ', data)
        } catch(err) {
          console.log("err handling err", err)
          if (errorCB) errorCB();
        }

      })
  };
}



export function logout( successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/users/logout')
      .then( (res) => {
        console.log('User successfully logged out - res: ', res);
        // remove auth token from https library
        dispatch(logoutUser());
          Https.removeAccesToken();
          if(successCB) successCB();
      })
      .catch((err) => {
        if(errorCB)errorCB();
        console.log('Couldnt logout user - ')
      })
  };
}

export function forgetPW(email, successCB, errorCB) {
  return function (dispatch, getState) {

    Https.post('/api/v1/users/postForgot', {email})
      .then( res => {
        console.log('User successfully logged in - res: ', res);
        if(successCB) successCB();
      })
      .catch(err => {
        const { response: {data}} = err;

        if (errorCB)  errorCB(data);
        console.log('Couldnt login user - ', data)
      })
  };
}

export function resetPW(email,token,password,confirmPassword, successCB, errorCB) {
  return function (dispatch, getState) {

    Https.post('/api/v1/users/setNewPassword', {email,token,password,confirmPassword})
      .then( res => {
        console.log('PW changed successfully  - res: ', res);
        if(successCB) successCB();
      })
      .catch(err => {
        const { response: {data}} = err;

        if (errorCB)  errorCB(data);
        console.log('Couldnt login user - ', data)
      })
  };
}

export function changePW(password,confirmPassword,  successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/users/postUpdatePassword', {password,  confirmPassword })
      .then( res => {
        console.log('User successfully logged in - res: ', res);
        if(successCB) successCB();
      })
      .catch(err => {
        console.log('Couldnt login user - ', err)
        const { response: {data}} = err;

        if (errorCB) errorCB(data);

      })
  };
}



export function logoutUser() {
  return {
    type: LOGOUT_USER
  }
}
