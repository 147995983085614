import React from 'react';
import BookPreviewCardAlternative from '../bookPreviewCardAlternative/BookPreviewCardAlternative';
import Heading from '../heading/Heading';
import aboSample from '../../assets/images/CoverAbo.png';
import CheckboxWithLabel from '../Checkbox/CheckboxWithLabel';
import './modalcontentbuyorsubscribe.css';
import {isMobile} from "../../utils/device";

function ModalContentBuyOrSubscribe({
                                        book,
                                        onClickKaufen,
                                        onClickAbo,
                                        singleBookPrice,
                                        flatratePrice,
                                        taxPercentageSingle,
                                        taxPercentageFlatrate,
                                        onClickCoupon
                                    }) {

    const [checkbox, setCheckbox] = React.useState(false);
    const [checkbox2, setCheckbox2] = React.useState(false);
    const changeCheckbox = (e) => {
        setCheckbox(e.target.checked);
    }

    const actionsCheckbox = {
        changeHandler: changeCheckbox
    };

    const defaultCheckbox = {
        label: (<React.Fragment>
            Mit Klick auf Einlösen/Kaufen/Abonnieren erkläre ich mich mit der Geltung der
            <a href={"/infothek/agb/"} target={"_blank"}>AGB</a> einverstanden.
            Von meinem <a href={"/infothek/agb/"} target={"_blank"}>Widerrufsrecht</a> und der
            <a href={"/infothek/datenschutz/"} target={"_blank"}>Datenschutzerklärung</a> habe ich Kenntnis genommen.
        </React.Fragment>),
        required: true
    }

    const changeCheckbox2 = (e) => {
        //this.setState({ checkbox: e.target.checked })
        setCheckbox2(e.target.checked);
    }

    const actionsCheckbox2 = {
        changeHandler: changeCheckbox2
    };

    const defaultCheckbox2 = {
        label: (<React.Fragment>
            Ich stimme der Ausführung des Vertrages vor Ablauf der Widerrufsfrist ausdrücklich zu. Ich habe zur
            Kenntnis genommen, dass mein Widerrufsrecht mit Beginn der Ausführung des
            Vertrags erlischt.
        </React.Fragment>),
        required: true
    }

    if (isMobile) {
        return (
            <div className="modalContainer ModalContentBuyOrSubscribe isMobile">
                <div className="mt-2">
                    <div className="headingStyle" style={{fontSize: "1.8em", lineHeight: "1.0em", paddingTop: 20}}>
                        Gesamten Inhalt sehen ?
                    </div>
                </div>
                <div id="scroll-to-top"/>
                <div className="mt-2">
                    <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox} {...actionsCheckbox} />
                </div>
                <div className="mt-2 mb-5">
                    <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox2} {...actionsCheckbox2} />
                </div>
                <div>
                    <div className="mt-2">
                        <Heading type="secondary">E-Book-Code vorhanden</Heading>
                    </div>
                    <div className=" p-1 mt-2" style={{fontSize: "1.15em"}}>
                        Du hast eine Druckausgabe gekauft und möchtest nun Zugang zu allen digitalen Inhalten und
                        Zusatzmaterialien des Titels zu
                        erhalten.
                    </div>
                    <div className=" p-1 mt-2">
                        <BookPreviewCardAlternative zustimmung={(checkbox === true && checkbox2 === true)}
                                                    coupon={true}
                                                    mediaTypes={book.mediaTypes}
                                                    isbn={book.isbn}
                                                    animate={false}
                                                    taxPercentage={null}
                                                    price={null}
                                                    title={book.title}
                                                    author={book.author}
                                                    onClickCoupon={onClickCoupon}

                        />
                    </div>
                </div>
                <div>
                    <div className="mt-2">
                        <Heading type="secondary">Einzelkauf</Heading>
                    </div>
                    <div className=" p-1 mt-2" style={{fontSize: "1.15em"}}>
                        Du musst {book.title} kaufen, um Zugang zu allen Inhalten und Zusatzmaterialien des Titels zu
                        erhalten.
                    </div>
                    <div className=" p-1 mt-2">

                        <BookPreviewCardAlternative zustimmung={(checkbox === true && checkbox2 === true)}
                                                    mediaTypes={book.mediaTypes}
                                                    isbn={book.isbn}
                                                    animate={false}
                                                    taxPercentage={taxPercentageSingle}
                                                    price={singleBookPrice}
                                                    title={book.title}
                                                    author={book.author}
                                                    onClickKaufen={
                                                        () => {
                                                            if (!checkbox || !checkbox2) return;
                                                            onClickKaufen()
                                                        }
                                                    }
                        />
                    </div>
                </div>
                <div className="divider">
                    <span>ODER</span>
                </div>
                <div>
                    <div className=" mt-2">
                        <div>
                            <Heading type="secondary">Unlimitierter Zugang</Heading>
                        </div>
                        <div>
                            <BookPreviewCardAlternative zustimmung={(checkbox === true && checkbox2 === true)}
                                                        animate={false}
                                                        aboBuy={true}
                                                        aboDisplay={false}
                                                        title={"Königs Erläuterungen Abonnement"}
                                                        taxPercentage={taxPercentageFlatrate}
                                                        price={flatratePrice}
                                                        description={"Erhalte Zugang zu allen Inhalten des Königs Erläuterungen Portals."}
                                                        coverURL={aboSample}
                                                        onClickKaufen={
                                                            () => {
                                                                if (!checkbox || !checkbox2) {
                                                                    document.getElementById('scroll-to-top').scrollIntoView();
                                                                    document.querySelectorAll("input[type=checkbox]").forEach(function (e) {
                                                                        if (!e.checked) e.parentElement.style.backgroundColor = "red"
                                                                    });
                                                                } else onClickAbo()
                                                            }
                                                        }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="modalContainer ModalContentBuyOrSubscribe">
            <div className="mt-2">
                <Heading type="secondary">Gesamten Inhalt sehen ? </Heading>
            </div>
            <div id="scroll-to-top"/>
            <div className="mt-2">
                <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox} {...actionsCheckbox} />
            </div>
            <div className="mt-2 mb-5">
                <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox2} {...actionsCheckbox2} />
            </div>
            <div>
                <div className="mt-2">
                    <Heading type="secondary">E-Book-Code vorhanden</Heading>
                </div>
                <div className=" p-1 mt-2" style={{fontSize: "1.15em"}}>
                    Du hast eine Druckausgabe gekauft und möchtest nun Zugang zu allen digitalen Inhalten und
                    Zusatzmaterialien des Titels zu
                    erhalten.
                </div>
                <div className=" p-1 mt-2">
                    <BookPreviewCardAlternative zustimmung={(checkbox === true && checkbox2 === true)}
                                                coupon={true}
                                                mediaTypes={book.mediaTypes}
                                                isbn={book.isbn}
                                                animate={false}
                                                taxPercentage={null}
                                                price={null}
                                                title={book.title}
                                                author={book.author}
                                                onClickCoupon={
                                                    () => {
                                                        if (!checkbox || !checkbox2) {
                                                            document.getElementById('scroll-to-top').scrollIntoView();
                                                            document.querySelectorAll("input[type=checkbox]").forEach(function (e) {
                                                                if (!e.checked) e.nextSibling.style.boxShadow = "inset 0 0 0 1px rgba(200,30,40,0.9), inset 0 -1px 0 rgba(200,30,40,0.9)"
                                                            });
                                                        } else onClickCoupon()
                                                    }
                                                }
                    />
                </div>
            </div>
            <div className="divider">
                <span>ODER</span>
            </div>
            <div>
                <div className="mt-2">
                    <Heading type="secondary">Einzelkauf</Heading>
                </div>
                <div className=" p-1 mt-2" style={{fontSize: "1.15em"}}>
                    Sie müssen {book.title} kaufen, um Zugang zu allen Inhalten und Zusatzmaterialien zu erhalten.
                </div>
                <div className="p-1 mt-2">
                    <BookPreviewCardAlternative zustimmung={(checkbox === true && checkbox2 === true)}
                                                mediaTypes={book.mediaTypes}
                                                isbn={book.isbn}
                                                animate={false}
                                                price={singleBookPrice}
                                                taxPercentage={taxPercentageSingle}
                                                title={book.title}
                                                author={book.author}
                                                description={book.description}
                                                onClickKaufen={
                                                    () => {
                                                        if (!checkbox || !checkbox2) {
                                                            document.getElementById('scroll-to-top').scrollIntoView();
                                                            document.querySelectorAll("input[type=checkbox]").forEach(function (e) {
                                                                if (!e.checked) e.nextSibling.style.boxShadow = "inset 0 0 0 1px rgba(200,30,40,0.9), inset 0 -1px 0 rgba(200,30,40,0.9)"
                                                            });
                                                        } else onClickKaufen()
                                                    }
                                                }
                    />
                </div>
            </div>
            <div className="divider">
                <span>ODER</span>
            </div>
            <div>
                <div className=" mt-2">
                    <div>
                        <Heading type="secondary">Unlimitierter Zugang</Heading>
                    </div>
                    <div>
                        <BookPreviewCardAlternative zustimmung={(checkbox === true && checkbox2 === true)}
                                                    animate={false}
                                                    aboBuy={true}
                                                    title={"Königs Erläuterungen Abonnement"}
                                                    price={flatratePrice}
                                                    taxPercentage={taxPercentageFlatrate}
                                                    description={"Erhalte Zugang zu allen Inhalten des Königs Erläuterungen Portals."}
                                                    coverURL={aboSample}
                                                    onClickKaufen={
                                                        () => {
                                                            if (!checkbox || !checkbox2) {
                                                                document.getElementById('scroll-to-top').scrollIntoView();
                                                                document.querySelectorAll("input[type=checkbox]").forEach(function (e) {
                                                                    if (!e.checked) e.nextSibling.style.boxShadow = "inset 0 0 0 1px rgba(200,30,40,0.9), inset 0 -1px 0 rgba(200,30,40,0.9)"
                                                                });
                                                            } else onClickAbo()
                                                        }
                                                    }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalContentBuyOrSubscribe
