import React from 'react';
import PropTypes from 'prop-types';

import image from '../../assets/images/image.svg';
import audio from '../../assets/images/audio.svg';
import video from '../../assets/images/video.svg';
import text from '../../assets/images/text.svg';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TitleAndAuthor from '../titleAndAuthor/TitleAndAuthor'
import Button from '../button/Button'
import './BookPreviewCardAlternative.css'
import mockImage from '../../assets/images/coverSample.jpg';
import {isMobile} from "../../utils/device";
import apiAddress from "../../services/api/apiAddress";
import Lottie from 'lottie-react-web';
import Tooltip from '@material-ui/core/Tooltip';
import loader from '../../assets/images/loader.json';

function Item({text}) {
    return (
        <div style={{fontSize: "1.4em", display: "flex", marginBottom: 4, marginTop: 4}}>
            <ArrowForwardIcon style={{color: "#005CA8", marginRight: 5}}/>
            {text}
        </div>
    )
}

class BookPreviewCardAlternative extends React.Component {

    constructor(props) {
        super(props);

        this.mediaImageURLs = this.parseMediaTypes(props.mediaTypes);
        this.imageContainer = React.createRef();
    }

    parseMediaTypes(mediaTypes) {
        let mediaImageURLs = [];

        for (let mediaType in mediaTypes) {
            switch (mediaTypes[mediaType]) {
                case "image":
                    mediaImageURLs.push(image);
                    break;
                case "video":
                    mediaImageURLs.push(video);
                    break;
                case "audio":
                    mediaImageURLs.push(audio);
                    break;
                case "text":
                    mediaImageURLs.push(text);
                    break;
                default:
                    break;
            }
        }

        return mediaImageURLs;
    }

    renderMediaItems() {

    }

    componentDidMount() {
        const {coverURL, isbn} = this.props;
        // Load the image asynchronously
        var url;
        if (coverURL) {
            url = coverURL;
        } else url = apiAddress + "/api/v1/content/books/media/" + isbn + "/Images/cover.jpg";
        if (!url) {
            url = mockImage;
        }

        var image = new Image();
        image.src = url;
        image.className = "bookPreviewCardLeftSide";
        image.height = 170;
        image.style = "cursor:pointer";
        image.alt = this.props.title;
        image.addEventListener("load", () => {
            if (this.imageContainer.current) {
                this.imageContainer.current.innerHTML = "";
                this.imageContainer.current.appendChild(image);
            }
        });
    }

    render() {
        if (isMobile) {
            return this.renderMobile();
        }
        /* eslint-disable no-unused-vars */
        const {
            title,
            author,
            description,
            price,
            index,
            animate,
            onClickKaufen,
            aboBuy,
            taxPercentage,
            zustimmung,
            coupon,
            onClickCoupon
        } = this.props;
        /* eslint-enable no-unused-vars */
        return (
            <div className="bookPreviewCardAlternativeContainer">
                <div ref={this.imageContainer} style={{height: "170px", width: "122px", marginRight: "30px"}}>
                    <Lottie
                        width={20}
                        options={{
                            animationData: loader,
                        }}
                    />
                </div>

                <div className="bookPreviewCardRightSide">
                    <div className="bookPreviewCardRightSideMainContent">
                        <TitleAndAuthor title={title} author={author}/>
                        {this.mediaImageURLs.length > 0 &&
                            <div className="bookPreviewCardMediaTypesContainer mb-2">
                                {this.mediaImageURLs.map((mediaImageURL, index) => {
                                    let fileType = mediaImageURL.split("/").pop().split(".")[0];
                                    let mediaType;
                                    switch (fileType) {
                                        case "image":
                                            mediaType = "Bild";
                                            break;
                                        case "audio":
                                            mediaType = "Audio";
                                            break;
                                        case "video":
                                            mediaType = "Video";
                                            break;
                                        case "text":
                                            mediaType = "Text";
                                            break;
                                        default:
                                            break;
                                    }
                                    return <Tooltip key={index} title={mediaType} aria-label={mediaType}>
                                        <img alt="mediaImageURL" src={mediaImageURL}/>
                                    </Tooltip>
                                })}
                            </div>}
                        <div className="bookPreviewCardDescription mb-md-2">{description}</div>
                        {price && !aboBuy && (<div className="mt-2">
                            <Item text={"einmalig " + price.toLocaleString('de-DE') + " €"}/>
                            <Item text={"3 Jahre Zugriff"}/>
                        </div>)}
                        {price && aboBuy && (<div className="mt-2">
                            <Item text={price.toLocaleString('de-DE') + " €/Monat"}/>
                            <Item text={"monatlich kündbar"}/>
                            <Item text={"automatische Abo-Verlängerung"}/>
                        </div>)}
                    </div>
                    {zustimmung === false && (
                        <div style={{color: 'red'}} className="mt-1 mb-1 pl-1">ABGs müssen akzeptiert werden</div>)}
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                        {/* PRE SUBSCRIPTION*/}
                        {price && aboBuy && <Button colorScheme="classic" title="Jetzt abonnieren" size="medium"
                                                    onClick={onClickKaufen}>
                            Für {price.toLocaleString('de-DE')} €/Monat abonnieren
                        </Button>}
                        {/* PRE PURCHASE*/}
                        {price && !aboBuy && <Button colorScheme="classic"
                                                     title="Jetzt kaufen"
                                                     size="medium"
                                                     onClick={onClickKaufen}>
                            Für {price.toLocaleString('de-DE')} € kaufen
                        </Button>}
                        {!price && coupon &&
                            <Button colorScheme="classic"
                                    title="Jetzt einlösen"
                                    size="medium"
                                    onClick={onClickCoupon}>
                                Jetzt einlösen
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderMobile() {
        /* eslint-disable no-unused-vars */
        const {
            title,
            author,
            description,
            price,
            index,
            animate,
            onClickKaufen,
            aboBuy,
            taxPercentage,
            zustimmung,
            coupon,
            onClickCoupon
        } = this.props;
        /* eslint-enable no-unused-vars */
        return (
            <div className="subPreviewCardAlternativeContainerMobile">
                <div style={{display: "flex"}}>
                    <div ref={this.imageContainer} style={{height: "170px", width: "122px"}}>
                        <Lottie style={{width: "20px"}}
                                options={{
                                    animationData: loader,
                                }}
                        />
                    </div>
                    <div className="bookPreviewCardRightSide" style={{flex: "1 1", width: "50%"}}>
                        <div className="bookPreviewCardRightSideMainContent">
                            <TitleAndAuthor title={title} author={author}/>
                            {this.mediaImageURLs.length > 0 &&
                                <div className="bookPreviewCardMediaTypesContainer mb-2">
                                    {this.mediaImageURLs.map((mediaImageURL, index) => {
                                        return <img key={index} alt="mediaImage" src={mediaImageURL}/>
                                    })}
                                </div>}
                            <div className="bookPreviewCardDescription mb-md-2">{description}</div>
                        </div>
                    </div>
                </div>
                <div className="my-2">
                    {zustimmung === false && (<div style={{color: 'red'}}>ABGs müssen akzeptiert werden</div>)}
                    {/* PRE SUBSCRIPTION*/}
                    {price && aboBuy && <Button colorScheme="classic" title="Jetzt kaufen" size="medium"
                                                onClick={onClickKaufen}>
                        Für {price.toLocaleString('de-DE')} €/Monat
                        abonnieren</Button>}
                    {/* PRE PURCHASE*/}
                    {price && !aboBuy && <Button colorScheme="classic" title="Jetzt kaufen" size="medium"
                                                 onClick={onClickKaufen}>
                        Für {price.toLocaleString('de-DE')} € kaufen</Button>}
                    {!price && coupon && (
                        <Button colorScheme="classic" title="Jetzt einlösen" size="medium"
                                onClick={onClickCoupon}>Jetzt einlösen</Button>
                    )}
                </div>
            </div>
        )
    }
}

BookPreviewCardAlternative.propTypes = {
    title: PropTypes.string,
    author: PropTypes.string,
    description: PropTypes.string,
    mediaTypes: PropTypes.arrayOf(PropTypes.string),
    coverURL: PropTypes.string,
}

export default BookPreviewCardAlternative;
