import React from 'react';
import Heading from "../heading/Heading";
import Button from "../button/Button";
class ModalContentRegisterSuccess extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div class="main-content-container container-fluid">
        <div class="row pt-5" style={{ minHeight: "100%" }}>
          <div class="col-12">
            <Heading type="secondary">Registrierung erfolgreich</Heading>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-12">
            <div stlye={{ fontSize: "1.15em" }}>
              Registrierung erfolgreich!
Herzlich willkommen im Club der Lektüren-Profis!
Du bist nun erfolgreich registriert und hast eine Bestätigung per Mail erhalten.
Es kann also losgehen – viel Spaß beim Stöbern!
              </div>
            <div class="mt-5" style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
              <Button onClick={this.props.onContinue} colorScheme="classic" size="medium">Fortfahren</Button>
            </div>
          </div>
        </div>
      </div>
    )

  }

}

export default ModalContentRegisterSuccess;
