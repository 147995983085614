import React from 'react';
import Heading from "../heading/Heading";
import Input from "../Input/Input";
import Button from "../button/Button";
import Https from "../../services/api/apiClient";
import CheckboxWithLabel from "../Checkbox/CheckboxWithLabel";
import BookPreviewCardAlternative from "../bookPreviewCardAlternative/BookPreviewCardAlternative";
import BookPreviewCard from "../bookPreviewCard/BookPreviewCard";

class ModalContentCoupon extends React.Component {

    couponInput = {
        placeholder: '1234-5-6A7B8C9D',
        label: 'E-Book-Code',
        type: 'text'
    }

    constructor(props) {
        super(props);
        this.state = {
            coupon: "",
            checked_valid: false,
            checked_backend_valid: false,
            valid: null,
            assign_tried: false,
            assignable_code: false,
            assigned_code: false,
            assigned_book: false,
            checkbox: false,
            checkbox2: false
        }
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props, "props after update");
    }

    changeCheckbox = (e) => {
        this.setState({checkbox: e.target.checked})
    }

    actionsCheckbox = {
        changeHandler: this.changeCheckbox
    };

    defaultCheckbox = {
        label: (<React.Fragment>
            Mit Klick auf Jetzt einl&ouml;sen erkl&auml;re ich mich mit der Geltung der <a href={"/infothek/agb/"}
                                                                                           target={"_blank"}>AGB</a> einverstanden.
            Von meinem <a href={"/infothek/agb/"} target={"_blank"}>Widerrufsrecht</a> und der <a
            href={"/infothek/datenschutz/"} target={"_blank"}>Datenschutzerkl&auml;rung</a> habe ich Kenntnis genommen.
        </React.Fragment>),
        required: true
    }

    changeCheckbox2 = (e) => {
        this.setState({checkbox2: e.target.checked})
    }

    actionsCheckbox2 = {
        changeHandler: this.changeCheckbox2
    };

    defaultCheckbox2 = {
        label: (<React.Fragment>
            Ich stimme der Ausf&uuml;hrung des Vertrages vor Ablauf der Widerrufsfrist ausdr&uuml;cklich zu. Ich habe
            zur
            Kenntnis genommen, dass mein Widerrufsrecht mit Beginn der Ausf&uuml;hrung des
            Vertrags erlischt.
        </React.Fragment>),
        required: true
    }

    render() {
        return (
            <div className="modalContainer">
                {!this.state.assigned_book ?
                    <div>
                        <div>
                            <Heading type="secondary">E-Book-Code</Heading>
                            Du hast ein Buch mit E-Book-Code gekauft und m&ouml;chtest nun Zugang zu allen Inhalten und
                            Zusatzmaterialien des Titels zu erhalten.
                        </div>
                        <div id="scroll-to-top"/>
                        {this.props.showCheckboxes &&
                            <>
                                <div className="mt-2">
                                    <CheckboxWithLabel inputName={"newsletter"}
                                                       checkbox={this.defaultCheckbox} {...(this.actionsCheckbox)} />
                                </div>
                                <div className="mt-2 mb-5">
                                    <CheckboxWithLabel inputName={"newsletter"}
                                                       checkbox={this.defaultCheckbox2} {...(this.actionsCheckbox2)} />
                                </div>
                            </>}
                        <div>
                            <Input defaultValue={''} input={this.couponInput} onChangeHandler={(e) => {
                                this.setState({coupon: e.target.value})
                            }}/>

                            {this.state.checked_valid ?
                                (!this.state.checked_backend_valid ?
                                        (!this.state.valid ?
                                                <div style={{color: 'red'}} className="mt-1 mb-1 pl-1">
                                                    E-Book-Code unvollst&auml;ndig, bitte pr&uuml;fen
                                                </div>
                                                : null
                                        )
                                        : null
                                )
                                : null
                            }

                            {this.state.checked_valid && this.state.checked_backend_valid ?
                                (!this.state.valid && !this.state.assignable_code ? (
                                        <div style={{color: 'red'}} className="mt-1 mb-1 pl-1">
                                            Es ist ein Fehler aufgetreten
                                        </div>
                                    ) : null
                                )
                                : null}

                            {this.state.assign_tried ?
                                (this.state.assigned_code ?
                                        <div className={'coupon_assigned'}>E-Book-Code ist dir zugewiesen</div>
                                        :
                                        (
                                            <div style={{color: 'red'}} className="mt-1 mb-1 pl-1">
                                                {this.state.assigned_error === 'NOTFOUND' ?
                                                    <span>E-Book-Code unbekannt</span>
                                                    : null
                                                }
                                                {this.state.assigned_error === 'TAKEN' ?
                                                    <span>Du hast diesen E-Book-Code bereits eingel&ouml;st</span>
                                                    : null
                                                }
                                                {this.state.assigned_error === 'TOOMANY' ?
                                                    <span>Dieser E-Book-Code ist bereits vergeben, melde dich beim
                    <a target={'_blank'} href={'/infothek/kontakt/'}>Kundenservice</a></span>
                                                    : null
                                                }
                                                {this.state.assigned_error === 'NOTLOGGEDIN' ?
                                                    <span>Du bist nicht angemeldet, bitte logge dich erneut ein</span>
                                                    : null
                                                }

                                            </div>
                                        )
                                ) : null // assigned_code
                            }
                        </div>
                        <div>
                            {this.props.showCheckboxes && (!this.state.checkbox || !this.state.checkbox2) && (
                                <div style={{color: 'red'}} className="mt-1 mb-1 pl-1">ABGs m&uuml;ssen akzeptiert
                                    werden</div>)}
                            {!this.state.assigned_code ?
                                <Button colorScheme="classic" title="Jetzt einl�sen" size="medium"
                                        onClick={this.onClickCoupon.bind(this)}>Jetzt
                                    einl&ouml;sen</Button> : null}
                        </div>
                    </div>
                    :null
                }
                {this.state.assigned_book && this.state.assigned_book.link ?
                    <div className={'assigned_book'}>
                        <Heading type="secondary">E-Book-Code erfolgreich zugeordnet</Heading>
                        <p>Die folgende Lekt&uuml;re ist nun f&uuml;r dich freigeschaltet</p>
                        <BookPreviewCard
                            price={''}
                            link={this.state.assigned_book.link}
                            couponcode={this.state.coupon}
                            mediaTypes={this.state.assigned_book.mediaTypes}
                            isbn={this.state.assigned_book.isbn}
                            title={this.state.assigned_book.title}
                            author={this.state.assigned_book.originalAuthor}
                            oAuthor={this.state.assigned_book.author}
                            animate={false}
                            description={this.state.assigned_book.description}>
                        </BookPreviewCard>
                    </div> : null}
            </div>
        )

    }

    onClickCoupon() {
        if (this.props.showCheckboxes && (!this.state.checkbox || !this.state.checkbox2)) {
            document.getElementById('scroll-to-top').scrollIntoView();
            document.querySelectorAll("input[type=checkbox]").forEach(function (e) {
                if (!e.checked) e.nextSibling.style.boxShadow = "inset 0 0 0 1px rgba(200,30,40,0.9), inset 0 -1px 0 rgba(200,30,40,0.9)"
            });
            return;
        }

        const valid = this.validateCode();
        this.setState({
            checked_valid: true,
            valid: valid,
            assignable_code: false,
            assigned_code: false,
            assign_tried: false,
            assigned_book: false
        });
        const code = this.state.coupon;
        if (valid) {
            this.setState({assign_tried: true});
            Https.get('/api/v1/codes/valid/' + code)
                .then(res => {
                    console.log('codes/valid', code, res);
                    if (res.hasOwnProperty('data') &&
                        res.data.hasOwnProperty('data') &&
                        Array.isArray(res.data.data) &&
                        res.data.data.length > 0 &&
                        res.data.data[0].hasOwnProperty('isbn')) {
                        this.setState({checked_backend_valid: true, assignable_code: true});
                        Https.get('/api/v1/codes/assign/' + code)
                            .then(res => {
                                console.log(res);
                                this.setState({assigned_code: true, assigned_book: res.data.data});
                            })
                            .catch(err => {
                                console.log('catch err', err);
                                if (err.response.data.error.errorCode === 'TAKEN') this.setState({
                                    assigned_code: false,
                                    assigned_error: 'TAKEN'
                                });
                                if (err.response.data.error.errorCode === 'TOOMANY') this.setState({
                                    assigned_code: false,
                                    assigned_error: 'TOOMANY'
                                });
                                if (err.response.status === 401) this.setState({
                                    assigned_code: false,
                                    assigned_error: 'NOTLOGGEDIN'
                                });
                            });
                    } else {
                        this.setState({
                            checked_valid: true,
                            checked_backend_valid: true,
                            assignable_code: false,
                            valid: false,
                            assigned_error: 'NOTFOUND'
                        });
                    }
                })
                .catch(err => {
                    console.log('Couldnt not fetch codes/valid - ', code, err);
                    this.setState({
                        checked_valid: true,
                        checked_backend_valid: true,
                        assignable_code: false,
                        valid: false
                    });
                });
        }
    }

    validateCode() {
        // see backend makeCode in components/codes/controller.js
        return /\d{4}-\d-[ACDEFGHKLMNPQRSTUVWXYZ2345679]{8}/.test(this.state.coupon);
    }
}

export default ModalContentCoupon;
