const fetchGetRetry = async (url, options, tryCount) => {
    const response = await fetch(url, options).catch(
        err => {
            console.log("Der Server ist momentan nicht erreichbar", err);
            return false;
        }
    );
    if (!response) {
        return false;
    }
    const status = response.status;
    if (status >= 200 && status <= 299)
        return response;
    return false;
}

const APIGet = async (API_URL_Method) => {
    let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "User-Agent": "bange-shop"
    };
    const options = {
        headers: headers,
        method: "GET"
    }
    try {
        let response = false;
        for (let i = 1; i <= 3; i++) {
            response = await fetchGetRetry(API_URL_Method, options, i);
            if (response)
                break;
        }
        if (response) {
            return await response.json();
        } else {
            throw new Error("the connection to the API could not be established");
        }
    } catch (err) {
        console.log(err);
        return false;
    }
}

export default APIGet;
