export function getValue( Key, Obj, Default ){

    if( typeof Obj == "undefined")
        Obj = null;
    if( typeof Default == "undefined")
        Default = null;
        
    if( Obj !== null ){

        if( typeof Obj === "object" ){

            if( Obj.hasOwnProperty( Key ) ){

                if( typeof Obj[ Key ] === "boolean"){
                    return Obj[ Key ];
                }else{
                    if( !exports.empty( Obj[ Key ] ) )
                        return Obj[ Key ];
                }
            }
        }
    }
    return Default;
}

export function parseDate(date){
    const parsed = Date.parse(date);

    if (!isNaN(parsed))
      return parsed;
     
    return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

export function isEquivalent(a, b){
    
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

export function time(){

    return Math.ceil( Date.now() / 1000 );
}

export function delete_array_value( haystack, needle ){

    if( Array.isArray( haystack ) === false )
        throw new Error ("Function delete_array_value: Haystack is no array");

    let index;
    if( ( index = haystack.indexOf( needle ) ) >= 0 ){

        haystack.splice( index, 1 );
    }
    return haystack;
}

/** 
 * Prüft, ob ein Wert in einem Array existiert
 * @param {string} needle Der gesuchte Wert
 * @param {[]} haystack Das zu durchsuchende Array
 * @returns bool
*/
export function in_array( needle, haystack ){

    if( Array.isArray( haystack ) === false )
        throw new Error ("Function in_array: Haystack is no array");

    if ( haystack.indexOf( needle ) >= 0 )
        return true;
    else
        return false;
}

/**
 * Sortiert ein Array zufällig
 * @param {*[]} 
 * @returns {*[]}
 */
export function functionshuffle( array ){

    let ctr = array.length;
    let temp;
    let index;

    while (ctr > 0) {

        index = Math.floor(Math.random() * ctr);

        ctr--;

        temp = array[ctr];
        array[ctr] = array[index];
        array[index] = temp;
    }
    return array;
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
export function empty(obj) {
    if (typeof obj === "undefined")
        return true;

    if (obj === null)
        return true;

    if (Array.isArray(obj) && obj.length === 0)
        return true;

    if (typeof obj === "object" && Object.keys(obj).length === 0)
        return true;

    return obj === false || obj === 0 || obj === 0.0 || obj === "0" || obj === "";

}

/**
 * Fügt 2 Arrays zusammen, entfernt doppelte Werte und gibt das Array zurück
 * @param {*[]} array1 
 * @param {*[]} array2 
 * @returns {*[]}
 */
export function merge_array( array1, array2 ){

    const result_array = [];
    const arr = array1.concat(array2);
    let len   = arr.length;
    const assoc = {};

    while(len--){
        const item = arr[len];

        if( !assoc[item] ){

            result_array.unshift(item);
            assoc[item] = true;
        }
    }
    return result_array;
}

export function addSlashes(link) {
    if (!link) return '';
    if (link.slice(-1) !== '/') link = link.concat('/');
    if (link.slice(0,1) !== '/') link = '/' + link;
    return link;
}