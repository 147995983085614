import React, {Fragment} from 'react';
import Heading from "../heading/Heading";
import BookPreviewCardAlternative from '../bookPreviewCardAlternative/BookPreviewCardAlternative';
import {withRouter} from "react-router-dom";
import Lottie from 'lottie-react-web'
import loader from '../../assets/images/loader.json';
import {connect} from 'react-redux';
import {
    createPayment
} from '../../reducers/payment/actions';
import {isMobile} from "../../utils/device";

//let PAYPAL;
// eslint-disable-next-line
const sandbox = document.location.hostname === 'localhost' || document.location.hostname.substring(0, 3) === 'dev';
const prodMode = sandbox ? "sandbox" : "live";


class ModalContentBuyCheckout extends React.Component {


    constructor(props) {
        super(props);
        this.state = {clientId: "", loading: false, error: false, loadingText: "Ihre Anfrage wird bearbeitet"};
    }

    componentDidMount() {
        // load script for ppp
        const script = document.createElement("script");

        script.src = "https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js";
        //script.scr = "https://www.paypalobjects.com/webstatic/ppplusdcc/ppplusdcc.min.js";
        //script.async = true;

        document.body.appendChild(script);
        this.props.createPayment([this.props.book.id], "SINGLE_BOOK", this.createPaymentSuccess, this.createPaymentError);
        this.setState({loading: true});

    }

    componentDidUpdate(prevProps) {
        if (prevProps.payment !== this.props.payment) {
            // payment resource changed, render paywall iframe

            window.PAYPAL.apps.PPP({
                "approvalUrl": this.props.payment.links[1].href,
                "placeholder": "ppplus",
                "mode": prodMode,
                "country": "DE",
                "language": "de_DE"
            });
            this.setState({loading: false})
        }
    }

    componentWillUnmount() {
    }

    createPaymentSuccess = () => {

    }

    createPaymentError = (err) => {
        console.log(err, " failed to create Payment Resource");
        this.setState({error: true, loading: false});
    }

    render() {
        if (isMobile) {
            return (<div className="modalContainer ModalContentBuyCheckout isMobile">
                <div className="mb-2">
                    <div className="headingStyle"
                         style={{fontSize: "1.8em", lineHeight: "1.0em", paddingTop: 20}}>
                        Zahlung bestätigen
                    </div>
                </div>
                <div className="mb-2" style={{fontSize: "1.15em"}}>
                    <p>
                        Zum Abschluss des Kaufprozesses wird auf PayPal weitergeleitet.
                        Es wird kein PayPal-Konto benötigt.
                        Die Datensicherheit wird durch die SSL-Verschlüsselung gewährleistet.
                    </p>
                </div>
                <div>
                    <BookPreviewCardAlternative mediaTypes={this.props.book.mediaTypes}
                                                isbn={this.props.book.isbn}
                                                title={this.props.book.title}
                                                author={this.props.book.author}
                                                animate={false}
                                                taxPercentage={this.props.taxPercentage}
                                                price={this.props.price}
                                                description={this.props.book.description}/>
                </div>
                {this.state.loading && (<Fragment>
                        <Lottie style={{width: "20%"}}
                                options={{
                                    animationData: loader,
                                }}
                        />
                        <div className="text-center">
                            {this.state.loadingText}
                        </div>
                    </Fragment>

                )}
                <div id="ppplus">

                </div>
                {this.state.error && (
                    <div style={{fontSize: "1.15em"}}>
                        Bei diesem Vorgang ist etwas schief gelaufen. Bitte erneut versuchen
                    </div>)}
            </div>)
        }
        return (<div className="modalContainer ModalContentBuyCheckout">
            <div>
                <Heading type="secondary">Zahlung bestätigen</Heading>
            </div>
            <div className="mb-2" style={{fontSize: "1.15em"}}>
                <p>
                    Zum Abschluss des Kaufprozesses wird auf PayPal weitergeleitet.
                    Es wird kein PayPal-Konto benötigt.
                    Die Datensicherheit wird durch die SSL-Verschlüsselung gewährleistet.
                </p>
            </div>
            <div>
                <BookPreviewCardAlternative mediaTypes={this.props.book.mediaTypes}
                                            isbn={this.props.book.isbn}
                                            title={this.props.book.title}
                                            author={this.props.book.author}
                                            animate={false}
                                            taxPercentage={this.props.taxPercentage}
                                            price={this.props.price}
                                            description={this.props.book.description}/>
            </div>
            {this.state.loading && (<Fragment>
                    <Lottie style={{width: "20%"}}
                            options={{
                                animationData: loader,
                            }}
                    />
                    <div className="text-center">
                        {this.state.loadingText}
                    </div>
                </Fragment>

            )}
            <div id="ppplus">

            </div>
            {this.state.error && (<div style={{fontSize: "1.15em"}}>
                Bei diesem Vorgang ist etwas schief gelaufen. Bitte erneut versuchen
            </div>)}
        </div>)

    }

}

const mapStateToProps = state => ({
    payment: state.payment.paymentResource,
});

const mapDispatchToProps = dispatch => ({
    createPayment: (bookid, productCode, successCb, errorCb) => dispatch(createPayment(bookid, productCode, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalContentBuyCheckout));
