import Https from "../../services/api/apiClient";
export const FETCH_USERS = 'FETCH_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_BOOKS_LIST = 'USER_BOOKS_LIST';
export const USER_ACTIVE_SUBSCRIPTION = 'USER_ACTIVE_SUBSCRIPTION';
export const USER_OLD_SUBSCRIPTIONS = 'USER_OLD_SUBSCRIPTIONS';
export const USER_COUPON_BOOKS = 'USER_COUPON_BOOKS';
export const FETCH_USERS_CODES = 'FETCH_USERS_CODES';

export function createCustomer(customer, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/users/postSignup', {...customer})
      .then( res => {
        successCB();
      })
      .catch( err => {
       // const { response: {data}} = err;
        if (errorCB) errorCB(err);
        console.log('Couldnt create customer - ', err)
      })
  };
}

export function sendContactForm(email,message, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/users/contact', {email:email,message:message})
      .then( res => {
       if (successCB) successCB();
      })
      .catch( err => {
       // const { response: {data}} = err;
        if (errorCB) errorCB(err);
        console.log('Couldnt create customer - ', err)
      })
  };
}


export function getOrderedBooks(successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/order/book')
      .then(res => {
        dispatch({
          type: USER_BOOKS_LIST,
          books: res.data.data.books
        })
        if (successCB)successCB();
      })
      .catch(err => {
        //onst {response: {data}} = err;
        // empty book list
        dispatch({
          type: USER_BOOKS_LIST,
          books: {}
        })
        if (errorCB)errorCB(err);
        console.log('Couldnt not fetch chapter ', err)
      })
  };
}

export function getUserActiveSubscription(searchTerm, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/order/subscription')
            .then(res => {
                dispatch({
                    type: USER_ACTIVE_SUBSCRIPTION,
                    subscription: res.data.data.subscription
                })
                if (successCB) successCB(res.data.data.subscription);
            })
            .catch(err => {
                dispatch({
                    type: USER_ACTIVE_SUBSCRIPTION,
                    subscription: null
                })
                if (errorCB) errorCB(err);
                console.log('Couldnt not fetch chapter ', err)
            })
    };
}

export function suspendUserSubscription(successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/order/subscription/suspend/')
      .then(res => {
        //console.log(res);
        // got canceled in our database
        dispatch({
          type: USER_ACTIVE_SUBSCRIPTION,
          subscription: null
        })
        if(successCB) successCB(res);
      })
      .catch(err => {
        if(errorCB) errorCB(err);
      })
  };
}




export function createAccountUser(customer, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/users/registerAccountUser', {...customer})
      .then( res => {
        successCB();
      })
      .catch( err => {
        //const { response: {data}} = err;
        if (errorCB) errorCB(err);
        console.log('Couldnt create customer - ', err)
      })
  };
}

export function deleteAccountUser(customer, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/users/deleteAccountUser', {...customer})
      .then( res => {
        successCB();
      })
      .catch( err => {
        //const { response: {data}} = err;
        if (errorCB) errorCB(err);
        console.log('Couldnt create customer - ', err)
      })
  };
}

export function updateAccount(info, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/users/postUpdateProfile', {...info})
      .then( (res) => {
        // console.log('account successfully updated - res: ', res);
        if(res.data.data.state === "own_change") {
          dispatch({
            type: UPDATE_USER,
            user: res.data.data.user,

          });
        }

        if (successCB) successCB(res.data.data);
      })
      .catch(err => {
       // const { response: {data}} = err;
        if (errorCB) errorCB(err);
        console.log('Couldnt update account - ', err)
      })
  };
}

export function fetchAllUsers(successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/users/')
      .then( res => {
        //console.log('fetchAllUsers - res: ', res);
        if (successCB) successCB(res.data.data);
        dispatch({
          type: FETCH_USERS,
          user: res.data.data
        });
      })
      .catch(err => {
        if (errorCB) errorCB(err);
        //console.log('Couldnt not fetch fetchAllUsers - ', err)
      })
  };
}

export function fetchAllUsersCodes(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/users/codes')
            .then( res => {
                console.log('fetchAllUsersCodes - res: ', res);
                if (successCB) successCB(res.data.data);
                dispatch({
                    type: FETCH_USERS_CODES,
                    users_codes: res.data.data
                });
            })
            .catch(err => {
                if (errorCB) errorCB(err);
                //console.log('Couldnt not fetch fetchAllUsers - ', err)
            })
    };
}

export function getUserOldsubscriptions(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/order/oldsubscriptions')
            .then(res => {
                if(successCB) successCB(res.data.data);
                dispatch({
                    type: USER_OLD_SUBSCRIPTIONS,
                    oldsubscriptions: res.data.data
                });
            })
            .catch(err => {
                if(errorCB) errorCB();
            })
    };
}

export function getCouponBooks(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/codes/user')
            .then(res => {
                successCB(res.data.data);
                dispatch({
                    type: USER_COUPON_BOOKS,
                    usercouponbooks: res.data.data
                });
            })
            .catch(err => {
                if (errorCB) errorCB(err);
            })
    };
}

