import Https from "../../services/api/apiClient";
export const  GET_PRODUCTS = "GET_PRODUCTS";
export const  GET_BOOKS = "GET_BOOKS";
export const  GET_PRODUCT_SINGLE_BOOK = "GET_PRODUCT_SINGLE_BOOK";
export const  GET_PRODUCT_FLATRATE = "GET_PRODUCT_FLATRATE";

export function getProducts(successCB, errorCB) {
    return function (dispatch, getState) {
      Https.get('/api/v1/products/all')
        .then( res => {
          // console.log('got all products ', res);
          dispatch({
              type: GET_PRODUCTS,
              products: res.data.data.products
          })
          if (successCB) successCB();
        })
        .catch( err => {
          if (errorCB) errorCB(err);
          console.log('Couldnt get all products - ', err)
        })
    };
  }

 export function getProduct(code,successCB, errorCB) {
    return function (dispatch, getState) {
      Https.get('/api/v1/products/one/'+code)
        .then( res => {
          // console.log('got all products ', res);
          // check which product we got
          const product = res.data.data.product;
          if (product.code === "SINGLE_BOOK") {
            dispatch({
                type: GET_PRODUCT_SINGLE_BOOK,
                singleBook: res.data.data.product
            })
          }

          if (product.code === "FLATRATE") {
            dispatch({
                type: GET_PRODUCT_FLATRATE,
                flatrate: res.data.data.product
            })
          }
          
          if (successCB) successCB();
        })
        .catch( err => {
         
          if (errorCB) errorCB(err);
          console.log('Couldnt get all products - ', err)
        })
    };
  } 

  export function updateProduct(newProduct,successCB, errorCB) {
    return function (dispatch, getState) {
      Https.post('/api/v1/products/update',{newProduct})
        .then( res => {
          // console.log('updated product', res);
          if (successCB) successCB();
        })
        .catch( err => {
         
          if (errorCB) errorCB(err);
          console.log('Couldnt get all products - ', err);
        })
    };
  }

export function getBooks(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/content/books')
            .then( res => {
                // console.log('got all books ', res);
                dispatch({
                    type: GET_BOOKS,
                    books: res.data.data
                })
                if (successCB) successCB();
            })
            .catch( err => {
                if (errorCB) errorCB(err);
                console.log('Couldnt get all books - ', err)
            })
    };
}