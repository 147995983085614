import {
  FETCH_BOOK,
  FETCH_BOOKS,
  FETCH_ALL_BOOKS,
  FETCH_CHAPTER,
  SEARCH_QUERY,
  SEARCH_RESULT,
  SEARCH_RESULT_INITIALS,
  SEARCH_RESULT_CHAPTERS,
} from "./actions";

const initialState = {
  books: [],
  currentChapter: null,
  currentBook: null,
  searchResult: [],
  searchTerm: ""
};

export default function content(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_BOOKS: {
      const {books} = action;
      return {
        ...state,
        books
      };
    }
    case FETCH_ALL_BOOKS: {
      const {books} = action;
      return {
        ...state,
        books
      };
    }
    case FETCH_CHAPTER: {
      const {currentChapter} = action;
      return {
        ...state,
        currentChapter
      };
    }
    case FETCH_BOOK: {
      const {currentBook} = action;
      return {
        ...state,
        currentBook:currentBook
      };
    }
    case SEARCH_RESULT: {
      const {searchResult, searchTerm,loadingResult} = action;
      return {
        ...state,
        searchResult: searchResult,
        searchTerm: searchTerm,
        loadingResult:loadingResult,
      };
    }
    case SEARCH_RESULT_INITIALS: {
      const {authorResults,titleResults,searchTermLetter} = action;
      return {
        ...state,
        searchResultsAuthor: authorResults,
        searchResultsTitle:titleResults,
        searchTermLetter: searchTermLetter
      };
    }
    case SEARCH_RESULT_CHAPTERS: {
      const {searchResultChapters} = action;
      return {
        ...state,
        searchResultChapters: searchResultChapters,
      };
    } 
    case SEARCH_QUERY: {
      const {searchTerm} = action;
      return {
        ...state,
        searchTerm: searchTerm
      };
    }
    default: {
      return state;
    }
  }
}
