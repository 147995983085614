import {
    FETCH_BOOKSHAVECODES,
    FETCH_BOOKSCODEUSERS
} from "./actions";

const initialState = {
    bookshavecodes: []
}

export default function codes(state = initialState, action = {}) {
    switch (action.type) {
        case FETCH_BOOKSHAVECODES: {
            const {bookshavecodes} = action;
            return {
                ...state,
                bookshavecodes
            };
        }
        case FETCH_BOOKSCODEUSERS: {
            const {bookscodesusers} = action;
            return {
                ...state,
                bookscodesusers
            };
        }

        default: {
            return state;
        }
    }
}
