
import Https from "../../services/api/apiClient";

export function orderBook(bookid, paymentid,saleid,successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/order/book/' + bookid,{paymentid: paymentid,saleid:saleid})
      .then(res => {
        if(successCB) successCB();
      })
      .catch(err => {
        if(errorCB) errorCB();
      })
  };
}

export function orderSubscription(subid,successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/order/subscription',{subid: subid})
      .then(res => {
        if(successCB) successCB();
      })
      .catch(err => {
        if(errorCB) errorCB();
      })
  };
}

