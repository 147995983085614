import Https from "../../services/api/apiClient";

export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const GET_PAYMENT = 'GET_PAYMENT';
export const EXECUTE_PAYMENT = 'EXECUTE_PAYMENT';
export const GET_SALE = 'GET_SALE';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';


export function createPayment(bookids, productCode, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.post('/api/v1/payment/create', {bookids: bookids, productCode: productCode})
            .then(
                res => {
                    dispatch({
                        type: CREATE_PAYMENT,
                        paymentRes: res.data.data.payment
                    })
                    if (successCB) successCB();
                },
                err => {
                    if (errorCB) errorCB(err);
                }
            )
            .catch(err => {
                dispatch({
                    type: CREATE_PAYMENT,
                    paymentRes: false
                })
                if (errorCB) errorCB(err);
            })
    };
}

export function getPayment(paymentid, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/payment/get/' + paymentid)
            .then(res => {
                dispatch({
                    type: GET_PAYMENT,
                    paymentRes: res.data.data.payment
                })
                if (successCB) successCB();
            })
            .catch(err => {
                if (errorCB) errorCB();
            })
    };
}

export function getSale(saleid, clientid, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/payment/sale/' + saleid)
            .then(res => {
                dispatch({
                    type: GET_SALE,
                    sale: res.data.data.sale
                })
                if (successCB) successCB();
            })
            .catch(err => {
                if (errorCB) errorCB();
            })
    };
}

export function executePayment(paymentid, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/payment/execute/' + paymentid)
            .then(res => {
                dispatch({
                    type: EXECUTE_PAYMENT,
                    payExe: res.data.data.paymentExec
                })
                if (successCB) successCB();
            })
            .catch(err => {
                let errcode = 0;
                console.log(err);
                if (err.response) {
                    let response = err.response;
                    errcode = response.data.data
                }
                if (errorCB) errorCB(errcode);
            })
    };
}

export function createSubscription(planid, bookid, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.post('/api/v1/payment/sub/create', {bookid: bookid})
            .then(res => {
                dispatch({
                    type: CREATE_SUBSCRIPTION,
                    subRes: res.data.data.subscription
                })
                if (successCB) successCB();
            })
            .catch(err => {
                if (errorCB) errorCB(err);
            })
    };
}

export function cancelSubscription(subscriptionid, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/payment/sub/cancelsub/' + subscriptionid)
            .then(res => {
                // got canceled online, now cancel in our database

                if (successCB) successCB();
            })
            .catch(err => {
                if (errorCB) errorCB();
            })
    };
}

export function orderSubscription(subscriptionId, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/payment/sub/order/' + subscriptionId, {})
            .then(res => {
                if (successCB) successCB();
            })
            .catch(err => {
                let errcode = 0;
                if (err.response) {
                    let response = err.response;
                    errcode = response.data.data
                }
                if (errorCB) errorCB(errcode);
            })
    };
}

export function getSubscription(subscriptionId, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/payment/sub/get/' + subscriptionId, {})
            .then(res => {
                dispatch({
                    type: GET_SUBSCRIPTION,
                    subRes: res.data.data.subscription
                })
                if (successCB) successCB();
            })
            .catch(err => {
                if (errorCB) errorCB();
            })
    };
}
