import React from "react";

import BookPreviewCard from '../componentsBange/bookPreviewCard/BookPreviewCard';
import {isMobile} from "../utils/device";
import Https from "../services/api/apiClient";
import * as axios from "axios";
import apiAddress from "../services/api/apiAddress";

class AlleArbeitshilfen extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            books: [],
            loading: true,
            wpContent: ''
        }
    }

    componentDidMount() {

        const apiClient = axios.create({
            baseURL: apiAddress,
            timeout: 10000,
            headers: {"Content-Type": "application/json"},
        });

        apiClient({method: 'get', url: '/api/v1/content/books'})
            .then(
                result => {
                    if (result.hasOwnProperty('data') && result.data.hasOwnProperty('data')) {
                        this.setState({loading: false, books: result.data.data});
                    }
                }
            )
            .catch((err) => {
                console.log('/api/v1/content/books', err);
            });

        Https.get('/api/v1/data/wp/page/1211')
            .then((res) => {
                if (res.hasOwnProperty('data') && res.data.hasOwnProperty('data') && res.data.data.hasOwnProperty('content')) {
                    this.setState({wpContent: res.data.data.content});
                }
            })
            .catch((err) => {
                console.log('get page 1211 err');
            });
    }

    render() {
        return (
            <div className={"main-content-container container" + (isMobile ? " d-flex" : "")}>
                <div className="row" style={{marginTop: isMobile ? "148px" : "120px"}}>
                    <div className="col-12">
                        <div id="wpContent"
                             style={{paddingTop: 20, paddingBottom: 20, marginTop: 45, fontSize: "1.4em"}}
                             dangerouslySetInnerHTML={{__html: String(this.state.wpContent).replaceAll('<a ', '<a target="_blank" ')}}>
                        </div>
                        {this.state.books && this.state.books.length > 0 && (
                            <div>
                                <div className="row">
                                    {this.state.books.map((element, index) => {
                                        if (element.title && element.isbn && element.link && element.author && element.originalAuthor)
                                            return (
                                                <div key={index} className="col-12 col-lg-4 p-3 my-3">
                                                    <BookPreviewCard animate={false}
                                                                     title={element.title ? element.title : ''}
                                                                     isbn={element.isbn ? element.isbn : ''}
                                                                     link={element.link ? element.link : ''}
                                                                     author={element.author ? element.author : ''}
                                                                     oAuthor={element.originalAuthor ? element.originalAuthor : ''}
                                                                     mediaTypes={element.mediaTypes ? element.mediaTypes : ''}
                                                                     coverURL={element.coverURL ? element.coverURL : ''}/>
                                                </div>
                                            )
                                        else return null;
                                    })
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        )

    }
}

AlleArbeitshilfen.propTypes = {
    /**
     * The small stats data.
     */

};

AlleArbeitshilfen.defaultProps = {};

export default AlleArbeitshilfen;
