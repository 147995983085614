import {
  FETCH_USERS,
  USER_BOOKS_LIST,
  USER_ACTIVE_SUBSCRIPTION,
  USER_OLD_SUBSCRIPTIONS,
  USER_COUPON_BOOKS,
  FETCH_USERS_CODES
} from "./actions";

const initialState = {
  allUsers: [],
  orderedBooks: [],
  activeSubscription: {},
  oldsubscriptions: [],
  usercouponbooks: []
};

export default function user(state = initialState, action={}) {
  switch(action.type) {
    case FETCH_USERS: {
      const { user } = action;
      return {
        ...state,
        allUsers: user
      };
    }
    case USER_BOOKS_LIST: {
      const { books } = action;
      return {
        ...state,
        orderedBooks: books
      };
    }
    case USER_ACTIVE_SUBSCRIPTION: {
      const  subscription  = action.subscription;
      return {
        ...state,
        activeSubscription: subscription
      };
    }
    case USER_OLD_SUBSCRIPTIONS: {
      const  oldsubscriptions  = action.oldsubscriptions;
      return {
        ...state,
        oldsubscriptions: oldsubscriptions
      };
    }
    case USER_COUPON_BOOKS: {
      const {usercouponbooks} = action;
      return {
        ...state,
        usercouponbooks
      };
    }
    // codes sum per user
    case FETCH_USERS_CODES: {
      const {users_codes} = action;
      return {
        ...state,
        users_codes
      };
    }
    default: {
      return state;
    }
  }
}
