import React, {Fragment} from 'react';
import './TitleAndAuthor.css';

function TitleAndAuthor({title, author,oAuthor,onClick}) {
    return (
        <Fragment>
            <div onClick={onClick} className="header">{title}</div>
            {oAuthor && <p className="author">{oAuthor}</p>}
            {!oAuthor && <p className="author">{author}</p>}
        </Fragment>
    )
}

export default TitleAndAuthor;
