import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import './Button.css'
import Lottie from 'lottie-react-web'
import loader from '../../assets/images/loader.json';
import {isMobile} from "../../utils/device";


function Button({className, colorScheme, size, onClick, children, link, loading, id}) {

    if (typeof id === "undefined")
        id = Date.now();

    const renderMobile = () => {
        if (loading) {
            return (
                <Fragment>
                    <div id={id} className={"button " + className + " " + colorScheme + " " + size + "Mobile Button"}
                         onClick={(e) => {
                             onClick(e)
                         }}>
                        <Lottie style={{width: "20px"}}
                                options={{
                                    animationData: loader,
                                }}
                        />
                    </div>
                </Fragment>
            )
        }

        if (link) return (
            <a href={link} style={{textDecoration: "none", outline: "none"}}>
                <div className={"button " + className + " " + colorScheme + " " + size + "Mobile Button"}
                     onClick={(e) => {
                         onClick(e)
                     }}>
                    {children}
                </div>
            </a>
        )

        return (
            <Fragment>
                <div id={id} className={"button " + className + " " + colorScheme + " " + size + "Mobile Button"}
                     onClick={(e) => {
                         onClick(e)
                     }}>
                    {children}
                </div>
            </Fragment>
        )
    }

    if (isMobile) {
        return renderMobile();
    }


    if (loading) {
        return (
            <Fragment>
                <div id={id} className={"button " + className + " " + colorScheme + " " + size + "Button"}
                     onClick={(e) => {
                         onClick(e)
                     }}>
                    <Lottie style={{width: "40px"}}
                            options={{
                                animationData: loader,
                            }}
                    />
                </div>
            </Fragment>
        )
    }

    if (link) return (
        //eslint-disable
        <a href={link} style={{textDecoration: "none", outline: "none"}}>
            <div className={"button " + className + " " + colorScheme + " " + size + "Button"} onClick={(e) => {
                onClick(e)
            }}>
                {children}
            </div>
        </a>
    )

    return (
        <Fragment>
            <div id={id} className={"button " + className + " " + colorScheme + " " + size + "Button"} onClick={(e) => {
                onClick(e)
            }}>
                {children}
            </div>
        </Fragment>

    )

}

Button.propTypes = {
    type: PropTypes.oneOf(["button", "submit"]),
    colorScheme: PropTypes.oneOf(["classic", "inverted", "transparent"]).isRequired,
    className: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
    onClick: PropTypes.func
}

Button.defaultProps = {
    className: '',
    onClick: (e) => {
        console.log("no custom onClick-handler has been implemented, using default handler..");
    }
}

export default Button;
