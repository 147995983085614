import React from "react";
import PropTypes from "prop-types";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import {LAYOUT_TYPES} from "../utils/constants";
import './default.css';

const ViewerLayout = ({children, noNavbar}) => {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {/* {state.showNav &&  <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={true} transparent={false}/>}
      {React.cloneElement(children, { showNav: toggleNav.bind(this)})} */}
            {!noNavbar &&
            <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={true} stickyTop={true} transparent={false}/>}
            {children}
        </div>
    );
}

ViewerLayout.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
};

ViewerLayout.defaultProps = {
    noNavbar: false,
    noFooter: true
};

export default ViewerLayout;
