import React from 'react';
import Heading from "../heading/Heading";
import Input from "../Input/Input";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import './modalcontentlogin.css';
import { isMobile } from "../../utils/device";

class ModalContentLogin extends React.Component {


  constructor(props) {
    super(props);
    this.state = { email: "", password: "", resetError: false };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
  }

  defaultInput = {
    label: 'E-Mail',
    type: 'text',
    required: true
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props, "props after update");
  }

  keyPressed = (key) => {
    const { email, password } = this.state;
    // eslint-disable-next-line
    if (key == "Enter") {
      // enter pressed, check if both fields are valid
      if (password.length > 0 && email.length > 0) {
        this.setState({ resetError: false });
        this.props.onLogin(email, password)
      }
    }
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value, resetError: true })
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value, resetError: true })
  }

  render() {
    if (isMobile) {
      return this.renderMobile();
    }
    return (
      <div class="modalContainer" onKeyDown={(e) => this.keyPressed(e.key)}>
        <div>
          <Heading type="secondary">Login</Heading>
        </div>
        <div style={{ fontSize: "1.15em", lineHeight: "1.15em" }}>
          Melden Sie sich in Ihrem Account an, um Titel kaufen zu können.
                    </div>

        <Input input={{ ...this.defaultInput, label: 'E-Mail', type: "email" }} onKeyDown={(e) => this.keyPressed(e.key)} onChangeHandler={this.onChangeEmail} />

        <div >
          <Input input={{ ...this.defaultInput, label: 'Passwort', type: "password" }} onKeyDown={(e) => this.keyPressed(e.key)} onChangeHandler={this.onChangePassword} />
        </div>
        {this.props.error && !this.state.resetError && (<div class="row my-3">
          <div class="col-12">
            <span style={{ color: "red", fontSize: "1.1em", lineHeight: "1.1em" }}>E-Mail oder Passwort inkorrekt. Bitte erneut versuchen.</span>
          </div>
        </div>)}
        <div class="d-flex justify-content-end my-3">
          <Link className="linkModal" to="/forgot-password">Passwort vergessen ? </Link>
        </div>
        <div class="mb-5">
          <Button loading={this.props.loading} colorScheme="classic" size="medium" onClick={() => { this.setState({ resetError: false }); this.props.onLogin(this.state.email, this.state.password) }}>Anmelden</Button>
        </div>
        <div class="mb-3">
          <Heading type="secondary">Noch keinen Account ?</Heading>
          <div style={{ fontSize: "1.15em", lineHeight: "1.15em" }}>
            Um einen Einzelkauf tätigen oder ein Abo abschließen zu können, musst du dich
  zuerst registrieren.
                    </div>

        </div>

        <div>
          <div class="linkModal" style={{ fontSize: "1.35em", cursor: "pointer" }} onClick={this.props.onClickRegister}>Hier Registrieren </div>
        </div>
      </div>
    )

  }

  renderMobile() {
    return (
      <div class="modalContainer" onKeyDown={(e) => this.keyPressed(e.key)}>
        <div>
          <Heading type="secondary">Login</Heading>
        </div>
        <div style={{ fontSize: "1.15em", lineHeight: "1.15em" }}>
          Melden Sie sich in Ihrem Account an, um Titel kaufen zu können.
                    </div>

        <Input name="email" input={{ ...this.defaultInput, label: 'E-Mail', type: "email" }} onKeyDown={(e) => this.keyPressed(e.key)} onChangeHandler={this.onChangeEmail} />

        <div >
          <Input name="password" input={{ ...this.defaultInput, label: 'Passwort', type: "password" }} onKeyDown={(e) => this.keyPressed(e.key)} onChangeHandler={this.onChangePassword} />
        </div>
        {this.props.error && !this.state.resetError && (<div class="row my-3">
          <div class="col-12">
            <span style={{ color: "red", fontSize: "1.1em", lineHeight: "1.1em" }}>E-Mail oder Passwort inkorrekt. Bitte erneut versuchen.</span>
          </div>
        </div>)}
        <div class="d-flex justify-content-end my-3">
          <Link className="linkModal" to="/forgot-password">Passwort vergessen ? </Link>
        </div>
        <div class="mb-5">
          <Button loading={this.props.loading} colorScheme="classic" size="medium" onClick={() => { this.setState({ resetError: false }); this.props.onLogin(this.state.email, this.state.password) }}>Anmelden</Button>
        </div>
        <div class="mb-3">
          <Heading type="secondary">Noch keinen Account ?</Heading>
          <div style={{ fontSize: "1.15em", lineHeight: "1.15em" }}>
            Um einen Einzelkauf tätigen oder ein Abo abschließen zu können, musst du dich
  zuerst registrieren.
                    </div>
        </div>
        <div>
          {/*  <div class="linkModal" style={{ fontSize: "1.35em", cursor: "pointer" }} onClick={()=>{window.location.href = '/register'}}>Hier Registrieren </div> */}
          <div class="linkModal" style={{ fontSize: "1.35em", cursor: "pointer" }} onClick={this.props.onClickRegister}>Hier Registrieren </div>
        </div>
      </div>
    )
  }

}

export default ModalContentLogin;
