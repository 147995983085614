import {
  LOGIN_USER, LOGOUT_USER,
  REGISTER_USER
} from "./actions";
import {
  UPDATE_USER
} from "../user/actions";

const initialState = {
  accessToken: '',
  loadingIndicator: false,
  authenticated: false
};

export default function authentication(state = initialState, action={}) {
  switch(action.type) {
    case LOGIN_USER: {
      const { user, token } = action;
      return {
        ...state,
        authenticated: true,
        accessToken: token,
        user: user
      };
    }
    case LOGOUT_USER: {
      return {
        ...state,
        authenticated: false,
        accessToken: '',
        user: null
      };
    }
    case REGISTER_USER: {
      const { user } = action;

      return {
        ...state,
        user
      };
    }
    case UPDATE_USER: {
      const { user } = action;

      return {
        ...state,
        user
      };
    }
    default: {
      return state;
    }
  }
}
